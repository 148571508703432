import { Icon } from "grommet-icons";
import { History } from "history";
import { MouseEventHandler } from "react";

export interface IconButtonPropsBase {
    icon: Icon;
    primary?: boolean;
    type?: "button" | "reset" | "submit";
    color?: string;
    bgColor?: string;
    href?: string;
    size?: "small" | "medium" | "large";
    fill?: 'horizontal' | 'vertical' | boolean;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    propagateClick?: boolean;
}

export function getOnClick(history: History, props: IconButtonPropsBase): MouseEventHandler<HTMLButtonElement> | undefined {
    if (props.href) {
        return (e) => {
            if (!props.propagateClick) {
                e.preventDefault();
                e.stopPropagation();
            }
            history.push(props.href!);
        };
    }

    if (props.onClick) {
        return (e) => {
            if (!props.propagateClick) {
                e.preventDefault();
                e.stopPropagation();
            }
            props.onClick!(e);
        }
    }
}