import React, { useEffect } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropButton, Box, Avatar, Text } from 'grommet';
import { StatusUnknown } from "grommet-icons";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { AppState } from '../../store';
import { getCurrentSession, prolongSession } from '../../store/sessions/actions';
import { UserSession } from "../../store/sessions/types";
import SessionCountdown from "../common/SessionCountdown";
import Loading from '../common/Loading';
import { inDevMode } from "../../common";

const mapState = ({ session }: AppState) => ({
    session: session.current,
    loadingState: session.loadingState
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        prolongSession: (session: UserSession) => {
            dispatch(prolongSession(session));
        }
    }
}

const connector = connect(mapState, mapDispatch);

type AllProps = ConnectedProps<typeof connector>

const UserMenu = (props: AllProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (inDevMode() && props.loadingState === "INIT" && props.session.id) {
            dispatch(getCurrentSession(props.session));
        }
    })

    if (props.loadingState === "LOADING") {
        return <Loading inline />
    }


    if (!props.session.authenticated) {
        return (
            <Avatar background="accent-4" title="Click to sign in" flex={false}>
                <Link to="/signin">
                    <StatusUnknown />
                </Link>
            </Avatar>
        )
    }

    const dropMenu = (
        <Box pad="xsmall" background="light-2" align="stretch">
            <Box pad="small" border={{ side: "bottom" }}>
                <Text weight="bold">{props.session.name}</Text>
            </Box>
            <Box pad="small" title="Click to prolong session" onClick={() => props.prolongSession(props.session)}>
                <SessionCountdown />
            </Box>
        </Box>
    );

    const label = <Text size="large" weight="bold">{props.session.name[0].toUpperCase()}</Text>
    return (
        <Avatar border={{ size: 'small', color: 'accent-2' }} background="white" flex={false}>
            <DropButton fill="vertical" label={label} dropAlign={{ top: 'bottom' }} dropContent={dropMenu} plain />
        </Avatar>
    )
}

export default connector(UserMenu);