import React, { ChangeEvent, ClipboardEvent } from "react";
import { Box, Form, FormField, TextInput, TextArea, Text, Heading, CheckBox } from 'grommet';
import { Icon, Upload } from 'grommet-icons'
import filesize from 'filesize';
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from "redux-thunk";

import { addNoteFile, removeNoteFile, revertPreviousNoteVersion, editCurrentNote } from '../../store/notes/actions';
import { AppThunk } from "../../store/common/actions";
import { UserSession } from "../../store/sessions/types";
import { AppState } from "../../store";
import IconLabelButton from "../common/IconLabelButton"
import IconOnlyButton from "../common/IconOnlyButton"
import NotePreview from "./NotePreview";
import { FileData, FileDataType, FullNote } from "../../store/notes/types";
import { randomKey } from "../../crypto";
import { showErrorMessage } from "../../store/common/actions";

const FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 10Mb

interface FilesListProps {
    files: FileData[];
    cancelIcon: Icon;
    removeFile: (file: FileData) => void
}

const FilesList = (props: FilesListProps) => {
    return (
        <React.Fragment>
            {props.files.map(x => (
                <Box key={x.key} direction="row" justify="between" elevation="medium" align="center" background="light-1" margin={{ vertical: "small" }}>
                    <Text margin={{ left: "medium" }}>{x.name}</Text>
                    <IconOnlyButton icon={props.cancelIcon} color="red" onClick={() => props.removeFile(x)} />
                </Box>
            ))}
        </React.Fragment>
    );
}

const mapState = ({ session, note }: AppState) => ({
    session: session.current,
    note: note.current
});

const dataChanged = (
        note: FullNote, 
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
        modify: (note: FullNote) => FullNote, 
        dispatch: ThunkDispatch<any, any, AnyAction>
    ) => {
    const position = event.target.selectionStart || 1;
    const target = event.target;
    const newNote = modify(note);
    dispatch(editCurrentNote(newNote));

    window.requestAnimationFrame(() => target.setSelectionRange(position, position));
}

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        titleChanged: (note: FullNote, event: React.ChangeEvent<HTMLInputElement>) => {
            dataChanged(note, event, x => ({ ...x, title: event.target.value }), dispatch);
        },
        textChanged: (note: FullNote, event: React.ChangeEvent<HTMLTextAreaElement>) => {
            dataChanged(note, event, x => ({ ...x, text: event.target.value }), dispatch);
        },
        favoriteChange: (note: FullNote, favorite: boolean) => {
            const newNote: FullNote = { ...note, favorite: favorite };
            dispatch(editCurrentNote(newNote));
        },
        removeFile: (note: FullNote, file: FileData) => {
            dispatch(removeNoteFile(note, file));
        },
        submit: (action: AppThunk, note: FullNote, session: UserSession) => { 
            dispatch(action(note, session));
        },
        cancel: (note: FullNote) => {
            dispatch(revertPreviousNoteVersion(note));
        }
    }
}

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>

interface Props {
    confirmText: string;
    confirmIcon: Icon;
    cancelIcon: Icon;
    onSubmit: AppThunk;
}

const NoteForm = (props: AllProps & Props) => {
    const dispatch = useDispatch();

    const getFileContent = (blob: File, fileType: FileDataType) => {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            const content = event.target?.result?.toString()!
            const fileData: FileData = { name: blob.name, size: blob.size, content: content, type: fileType, key: randomKey() };
            const contentSize = content.length;
            if (contentSize > FILE_SIZE_LIMIT) {
                const errorMessage = `File ${fileData.name} has size ${filesize(contentSize)} that more then a limit ${filesize(FILE_SIZE_LIMIT)}`;
                dispatch(showErrorMessage(errorMessage));
                return;
            }
            dispatch(addNoteFile(props.note, fileData));
        }
        reader.readAsDataURL(blob);
    }

    const onFilePaste = (e: ClipboardEvent<HTMLDivElement>) => {
        for (let i = 0; i < e.clipboardData.items.length; i++) {
            const item = e.clipboardData.items[i];
            if (item.type.indexOf("image") === 0) {
                const blob = item.getAsFile()!;
                getFileContent(blob, FileDataType.Image);
            }
        }
    }

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        for (let i = 0; i < (e.target?.files?.length || 0); i++) {
            const file = e.target.files![i];
            getFileContent(file, file.type.startsWith("image") ? FileDataType.Image : FileDataType.Other);
        }
    }

    const labelStyle = {
        display: "inline-block",
        padding: "6px 12px",
        cursor: "pointer"
    }

    // todo: move form to other component
    return (
        <Box direction="row" alignSelf="stretch" alignContent="stretch" justify="stretch">
            <Box basis="1/2" pad="small" border={{ color: "dark-6", side: "right", size: "small", style: "solid" }}>
                <Heading level="3" margin={{ vertical: "small" }}>Edit</Heading>
                <Form
                    onSubmit={() => props.submit(props.onSubmit, props.note, props.session)}
                    validate="blur"
                >
                    <FormField label="Title" name="title">
                        <TextInput
                            name="title"
                            placeholder="Note title ..."
                            value={props.note.title}
                            onChange={x => props.titleChanged(props.note, x)}
                        />
                    </FormField>

                    <FormField label="Text" name="text">
                        <Box height="medium">
                            <TextArea 
                                name="text" 
                                placeholder="Note text, Markdown supported ..." 
                                fill 
                                resize="vertical" 
                                size="small"
                                value={props.note.text}
                                onChange={x => props.textChanged(props.note, x)}
                            />
                        </Box>
                    </FormField>

                    <FormField label="Favorite" name="favorite">
                        <CheckBox 
                            label="Note is favorite" 
                            checked={props.note.favorite} 
                            onChange={x => props.favoriteChange(props.note, x.target.checked)} 
                        />
                    </FormField>

                    <Box height="xxsmall" background="light-5" justify="center" elevation="large" margin={{ vertical: "small" }} onPaste={onFilePaste} onClick={() => { }}>
                        <Text textAlign="center">Select the box and hit <Text weight="bold">CTRL+V</Text> to paste an image</Text>
                    </Box>
                    <FilesList
                        files={props.note.files.filter(x => x.type === FileDataType.Image)}
                        cancelIcon={props.cancelIcon}
                        removeFile={(file) => props.removeFile(props.note, file)} />

                    <Box height="xxsmall" background="light-5" justify="center" elevation="large" margin={{ vertical: "small" }}>
                        <label htmlFor="file-upload" style={labelStyle}>
                            <Box direction="row" justify="center">
                                <Upload />
                                <Text margin={{ left: "small" }}>Upload files</Text>
                                <input type="file" id="file-upload" multiple style={{ display: "none" }} onChange={onFileChange} />
                            </Box>
                        </label>
                    </Box>
                    <FilesList
                        files={props.note.files.filter(x => x.type === FileDataType.Other)}
                        cancelIcon={props.cancelIcon}
                        removeFile={(file) => props.removeFile(props.note!, file)} />

                    <Box direction="row" gap="medium" margin={{ vertical: "large" }}>
                        <IconLabelButton icon={props.confirmIcon} type="submit" primary label={props.confirmText} />
                        <IconLabelButton icon={props.cancelIcon} label="Cancel" onClick={() => props.cancel(props.note)} />
                    </Box>
                </Form>
            </Box>
            <Box basis="1/2" pad="small" border={{ color: "dark-6", side: "left", size: "small", style: "solid" }}>
                <Heading level="3" margin={{ vertical: "small" }}>Preview</Heading>
                <NotePreview />
            </Box>
        </Box>
    )
    // todo: add markdown instructions   
}

export default connector(NoteForm);