import { Reducer } from "redux";
import { COMMON_ERROR, PURGE_STATE, SIGN_OUT_COMPLETE } from "../common/types";
import { ADD_CARD, CARD_ADDED, CARD_CHANGED, CARD_REMOVED, DashboardActionTypes, DashboardState, GET_DASHBOARD, REMOVE_CARD, SET_DASHBOARD } from "./types";

const initState: DashboardState = {
    cards: [],
    loadingState: "INIT"
}

const reducer: Reducer<DashboardState> = (state = initState, action: DashboardActionTypes) => {
    switch (action.type) {
        case GET_DASHBOARD:
        case ADD_CARD:
        case REMOVE_CARD:
            return { ...state, loadingState: "LOADING" };

        case SET_DASHBOARD: 
            return { ...state, loadingState: "LOADED", cards: action.data };

        case CARD_ADDED:
            return { ...state, loadingState: "LOADED", cards: [ ...state.cards, action.data ] };

        case CARD_REMOVED:
            return { ...state, loadingState: "LOADED", cards: state.cards.filter(x => x.id !== action.data.id) };

        case CARD_CHANGED:
            return { ...state, cards: state.cards.map(x => x.id === action.data.id ? action.data : x) };

        case COMMON_ERROR:
            return { ...state, loadingState: "ERROR" };

        case SIGN_OUT_COMPLETE:
        case PURGE_STATE:
            return initState;
        
        default:
            return state;
    }
}

export { reducer as DashboardReducer }