import { Dispatch } from "redux";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import moment from "moment";

import {
    SessionActionTypes,
    UserSession,
    UserCredentials,
    SET_CURRENT_SESSION,
    GET_CURRENT_SESSION_REQUEST,
    SIGN_DATA_CHANGED,
    SIGN_IN_REQUEST,
    SIGN_OUT_DIALOG } from "./types";
import { SessionClient } from '../../grpc/generated/session_pb_service';
import { ProlongResult, SessionInfo, SignInRequest } from '../../grpc/generated/session_pb';
import { GrpcClient } from "../../grpc/helpers";
import { getCryptoKey } from "../../crypto";
import { COMMON_ERROR, SIGN_OUT_COMPLETE, WRONG_CREDS_ERROR_CODE } from "../common/types";
import { getCryptoPassword, inDevMode } from "../../common";
import { AppThunk } from "../common/actions";

const grpcClient = new GrpcClient<SessionClient>(SessionClient);

export const getCurrentSession: AppThunk = (session: UserSession) => {
    return async (dispatch: Dispatch<SessionActionTypes>) => {
        if (!inDevMode()) {
            throw new Error("getCurrentSession allowed only in DEV mode");
        }

        dispatch({ type: GET_CURRENT_SESSION_REQUEST });

        const currentSession = await grpcClient.unaryCall<Empty, SessionInfo>(x => x.getCurrent, new Empty(), session, dispatch)
            
        const expiresOn = moment().add(currentSession.getExpiressec(), "seconds");
        const userSession: UserSession = {
            id: currentSession.getId(),
            name: currentSession.getUsername(),
            key: getCryptoPassword(),
            expiresOn: expiresOn.toDate(),
            authenticated: currentSession.getLoggedin()
        };

        dispatch({ type: SET_CURRENT_SESSION, session: userSession });
    }
}

export const signInInfoChanged: AppThunk = (creds: UserCredentials) => {
    return (dispatch: Dispatch<SessionActionTypes>) => {
        dispatch({ type: SIGN_DATA_CHANGED, data: creds });
    }
}

export const signInUser: AppThunk = (creds: UserCredentials) => {
    return async (dispatch: Dispatch<SessionActionTypes>) => {
        dispatch({ type: SIGN_IN_REQUEST });

        const request = new SignInRequest();
        request.setLogin(creds.login);
        request.setPassword(creds.password);

        const session = await grpcClient.unaryCallNoSession<SignInRequest, SessionInfo>(x => x.startNew, request, dispatch);

        if (!session.getLoggedin()) {
            dispatch({ type: COMMON_ERROR, error: { code: WRONG_CREDS_ERROR_CODE, message: 'Wrong credentials' } }); // todo: refactor with exception on backend
        } else {
            const key = getCryptoKey(creds);
            const expiresOn = moment().add(session.getExpiressec(), "seconds");
            const userSession: UserSession = {
                id: session.getId(),
                name: session.getUsername(),
                key: key,
                expiresOn: expiresOn.toDate(),
                authenticated: true
            };

            dispatch({ type: SET_CURRENT_SESSION, session: userSession });
        }
    }
}

export const showHideSignOutNotificationDialog: AppThunk = (show: boolean) => {
    return (dispatch: Dispatch<SessionActionTypes>) => {
        dispatch({ type: SIGN_OUT_DIALOG, show: show });
    }
}

export const prolongSession: AppThunk = (session: UserSession) => {
    return async (dispatch: Dispatch<SessionActionTypes>) => {
        const result = await grpcClient.unaryCall<Empty, ProlongResult>(x => x.prolong, new Empty(), session, dispatch);
            
        session.expiresOn = moment().add(result.getExpiressec(), "seconds").toDate();
        dispatch({ type: SET_CURRENT_SESSION, session: session, prolonged: true });
        dispatch({ type: SIGN_OUT_DIALOG, show: false });
            
    }
}

export const sessionFinished: AppThunk = () => {
    return (dispatch: Dispatch<SessionActionTypes>) => {
        dispatch({ type: SIGN_OUT_COMPLETE });
    }
}
