import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Box, Heading, Layer } from "grommet";
import QRCode from "qrcode.react";

import { AppState } from "../../store";
import { hideQRCode } from "../../store/notes/actions";

const mapState = ({ note }: AppState) => ({
    qrcode: note.qrcode
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => { 
    return {
        hide: () => {
            dispatch(hideQRCode());
        }
    }
};

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>;

const QRCodeDialog = (props: AllProps) => {
    if (!props.qrcode) {
        return <React.Fragment />
    }

    return (
        <Layer onEsc={props.hide} onClickOutside={props.hide}>
            <Box pad="medium" align="center">
                <Heading level="2" margin={{top: "none"}}>{props.qrcode.title}</Heading>
                <QRCode level="H" size={256} value={props.qrcode.valueToCode} />
            </Box>
            
        </Layer>
    )
}

export default connector(QRCodeDialog);