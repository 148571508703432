import { Reducer } from "redux";
import { saveSessionInDevMode, getSessionId } from "../../common";
import { COMMON_ERROR, SIGN_OUT_COMPLETE, WRONG_CREDS_ERROR_CODE } from "../common/types";

import { 
    SessionState, 
    SessionActionTypes, 
    GET_CURRENT_SESSION_REQUEST, 
    SET_CURRENT_SESSION, 
    SIGN_IN_REQUEST,
    SIGN_DATA_CHANGED, 
    SIGN_OUT_DIALOG,
    PROLONG_SESSION } from "./types";

export const initialState: SessionState = {
    current: {
        id: getSessionId(), // loads only in DEV mode
        name: 'anonymous',
        key: '',
        expiresOn: new Date(),
        authenticated: false
    },
    credentials: {
        login: '',
        password: ''
    },
    loadingState: "INIT",
    showSessionEndDialog: false
};

const reducer: Reducer<SessionState> = (state = initialState, action: SessionActionTypes) => {
    switch (action.type) {
        case GET_CURRENT_SESSION_REQUEST:
        case SIGN_IN_REQUEST:
            return { ...state, loadingState: "LOADING" };

        case SET_CURRENT_SESSION:
            saveSessionInDevMode(action.session);
            return { ...state, loadingState: "LOADED", current: action.session, credentials: { login: '', password: '' } };

        case SIGN_DATA_CHANGED:
            return { ...state, credentials: action.data };

        case SIGN_OUT_DIALOG:
            return { ...state, showSessionEndDialog: action.show };

        case PROLONG_SESSION:
            return { ...state, current: { ...state.current, expiresOn: action.expiresOn }, showSessionEndDialog: false };

        case SIGN_OUT_COMPLETE:
            return initialState;

        case COMMON_ERROR:
            if (action.error.code === WRONG_CREDS_ERROR_CODE) {
                return { ...state, loadingState: "INIT", error: action.error.message };
            }

            return { ...state, loadingState: "ERROR" };

        default:
            return state;
    }
}

export { reducer as SessionReducer };