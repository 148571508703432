import { History } from "history";
import { Dispatch } from "react";
import { AnyAction, Middleware } from "redux";
import { inDevMode } from "../common";
import { CANCEL_NOTE_EDIT, NoteActionTypes, NOTE_CREATED, NOTE_DELETED, NOTE_UPDATED } from "./notes/types";
import { SessionActionTypes, SET_CURRENT_SESSION } from "./sessions/types";

export const createRedirectMiddleware = (history: History): Middleware => {
    return () => (next: Dispatch<AnyAction>) => (action: SessionActionTypes | NoteActionTypes) => {
        let redirectTo;
        switch (action.type) {
            case NOTE_CREATED:
            case NOTE_UPDATED:
            case CANCEL_NOTE_EDIT: // todo: redirect to "/notes" when user start editing from note list
                redirectTo = `/note/${action.data.id}/view`;
                break;

            case NOTE_DELETED:
                redirectTo = "/notes";
                break;

            case SET_CURRENT_SESSION:
                if (!inDevMode() && action.prolonged !== true) {
                    redirectTo = "/";
                }
                break;
        }

        if (redirectTo) {
            history.push(redirectTo);
            console.log("redirect to", redirectTo, "after action", action.type);
        }

        return next(action);
    }
}