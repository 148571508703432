import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Heading, Text, Card, CardHeader, CardBody, CardFooter, Layer } from 'grommet';
import { Trash, Close } from 'grommet-icons';

import { AppState }  from '../../store'
import { hideDeleteNoteDialog, deleteNote } from '../../store/notes/actions';
import { FullNote, ShortNote } from '../../store/notes/types';
import { UserSession } from '../../store/sessions/types';
import IconLabelButton from '../common/IconLabelButton';

const mapState = ({ note, session }: AppState) => ({
    noteToDelete: note.noteToDelete,
    session: session.current
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => { 
    return {
        hideDeleteDialog: () => {
            dispatch(hideDeleteNoteDialog());
        },
        deleteNote: (note: ShortNote | FullNote, session: UserSession) => {
            dispatch(deleteNote(note, session));
        }
    }
};

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>

const DeleteNoteDialog = (props: AllProps) => {
    if (!props.noteToDelete) {
        return <React.Fragment />;
    }

    return (
        <Layer onEsc={props.hideDeleteDialog} onClickOutside={props.hideDeleteDialog}>
            <Card width="medium">
                <CardHeader pad="medium">
                    <Heading level="3" margin="none">Delete note?</Heading>
                </CardHeader>
                <CardBody pad="medium">
                    <Text>Do you really want to permanently delete note <strong>{props.noteToDelete.title}</strong>?</Text>
                </CardBody> 
                <CardFooter pad="medium" background="light-5">
                    <IconLabelButton icon={Trash} label="Delete" color="status-critical" onClick={() => props.deleteNote(props.noteToDelete!, props.session)} />
                    <IconLabelButton icon={Close} label="Cancel" onClick={props.hideDeleteDialog} />
                </CardFooter>
            </Card>
        </Layer>
    )
}

export default connector(DeleteNoteDialog);