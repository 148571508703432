import { Reducer } from "redux";
import { BUTTON_OVER, CLOSE_ERROR, CommonActionTypes, CommonState, COMMON_ERROR, PURGE_STATE, SET_MENU_OPENED, SET_VIEW_SIZE, SIGN_OUT_COMPLETE, ViewSize } from "./types";

const initialState: CommonState = {
    errors: [],
    buttonOver: new Map<string, boolean>(),
    viewSize: "large",
    mobileMenuOpened: false
}

const reducer: Reducer<CommonState> = (state = initialState, action: CommonActionTypes) => {
    switch (action.type) {
        case COMMON_ERROR:
            if (state.errors.find(x => x.code === action.error.code && x.message === action.error.message)) {
                console.log("Deduplicate error", action.error);
                return state;
            }
            
            return { ...state, errors: [ ...state.errors, action.error ] };

        case CLOSE_ERROR:
            return { ...state, errors: state.errors.filter(x => x !== action.error) };

        case BUTTON_OVER:
            if (state.buttonOver.get(action.id) !== action.over) {
                const buttonState = new Map<string, boolean>(state.buttonOver);
                buttonState.set(action.id, action.over);
                return { ...state, buttonOver: buttonState };
            }
            
            return state;

        case SET_VIEW_SIZE:
            console.log("setting view size", action.size);
            return { ...state, viewSize: action.size as ViewSize };

        case SET_MENU_OPENED:
            return { ...state, mobileMenuOpened: action.opened };

        case SIGN_OUT_COMPLETE:
        case PURGE_STATE:
            return initialState;

        default:
            return state;
    }
}

export { reducer as CommonReducer };