import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Menu } from "grommet";

import { AppState } from "../../store";
import { getNotes } from "../../store/notes/actions";
import { addCard } from "../../store/dashboard/actions";
import { ShortNote } from "../../store/notes/types";
import { UserSession } from "../../store/sessions/types";
import Loading from '../common/Loading';

const mapState = ({ session, dashboard, note }: AppState) => ({
    session: session.current,
    cards: dashboard.cards!,
    notesLoadingState: note.notesLoadingState,
    notes: note.allNotes
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        addCard: (note: ShortNote, session: UserSession) => {
            dispatch(addCard(note, session));
        }
    }
}

const connector = connect(mapState, mapDispatch)
type AllProps = ConnectedProps<typeof connector>

const AddDashboardCard = (props: AllProps) => {
    const dispatch = useDispatch();

    if (props.notesLoadingState === "LOADING") {
        return <Loading />
    }

    if (props.notesLoadingState === "INIT") {
        dispatch(getNotes(props.session));
        return <Loading />
    }

    const cardsIds = props.cards.map(x => x.id);
    const items = props.notes.filter(x => cardsIds.indexOf(x.id) < 0).map(x => ({ 
        label: x.title,
        onClick: () => { props.addCard(x, props.session) }
    }));

    return (
        <Menu label="Add card" items={items} disabled={items.length === 0} />
    )
}

export default connector(AddDashboardCard);