import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Box } from 'grommet';
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AppState } from '../../store';
import { getDashboard, removeCard } from "../../store/dashboard/actions";
import DashboardCard from "./DashboardCard";
import AddDashboardCard from "./AddDashboardCard";
import { DashboardCardData } from "../../store/dashboard/types";
import { UserSession } from "../../store/sessions/types";
import Loading from '../common/Loading';

const mapState = ({ session, dashboard }: AppState) => ({
    session: session.current,
    cards: dashboard.cards,
    loadingState: dashboard.loadingState
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        removeCard: (card: DashboardCardData, session: UserSession) => {
            dispatch(removeCard(card, session));
        }
    }
 }

const connector = connect(mapState, mapDispatch)
type AllProps = ConnectedProps<typeof connector>

const Dashboard = (props: AllProps) => {
    const dispatch = useDispatch();

    if (props.loadingState === "LOADING") {
        return <Loading />
    }

    if (props.loadingState === "INIT") {
        dispatch(getDashboard(props.session));
        return <Loading />
    }

    return (
        <React.Fragment>
            <Box direction="row">
                <AddDashboardCard />
            </Box>
            <Box direction="row"  justify="center" wrap={true}>
                {props.cards.map(x => <DashboardCard key={x.id} card={x} onRemove={card => props.removeCard(card, props.session)} />)}
            </Box>
        </React.Fragment>
    )
}

export default connector(Dashboard);