import React from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { Grommet } from 'grommet';
import { History } from 'history';
import { Router } from 'react-router-dom';

import { AppState } from './store';
import MainView from './components/layout/MainView';

interface MainProps {
    store: Store<AppState>;
    history: History;
}

const theme = {
    global: {
        colors: {
            brand: '#00739D',
            error: '#FF4040'
        },
        font: {
            family: 'Roboto',
            size: '18px',
            height: '20px',
        },
    },
};

export const App: React.FC<MainProps> = ({ store, history }) => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Grommet theme={theme} full>
                    <MainView />
                </Grommet>
            </Router>
        </Provider>
    );
}

export default App;
