import React, { useRef } from 'react';
import { Button, Box, Text, Drop } from 'grommet';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../store';
import { changeButtonOver } from "../../store/common/actions";
import { getOnClick, IconButtonPropsBase } from './IconButtonBase';

const mapState = ({ common }: AppState) => ({
    buttonState: common.buttonOver
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        setButtonOver: (id?: string, over?: boolean, shown?: boolean) => {
            if (over === shown) {
                return;
            }

            if (id) {
                dispatch(changeButtonOver(id, over));
            }
        }
    }
}

const connector = connect(mapState, mapDispatch)
type AllProps = ConnectedProps<typeof connector>

interface IconOnlyButtonProps extends IconButtonPropsBase {
    idKey?: string;
    title?: string;
    titleAlign?: "right" | "bottom";
}

const IconOnlyButton = (props: AllProps & IconOnlyButtonProps) => {
    const history = useHistory();
    const btnRef = useRef() as React.RefObject<HTMLButtonElement>;

    const onClick = getOnClick(history, props);

    const id = props.idKey;
    const showTitle = (props.title && props.buttonState.get(id || "")) || false

    return (
        <React.Fragment>
            <Button
                ref={btnRef}
                hoverIndicator="light-6"
                type={props.type}
                size={props.size}
                onClick={onClick}
                fill={props.fill}
                disabled={props.disabled}
                onMouseOver={() => props.setButtonOver(id, true, showTitle)}
                onMouseLeave={() => props.setButtonOver(id, false, showTitle)}
                onFocus={() => props.setButtonOver(id, true, showTitle)}
                onBlur={() => props.setButtonOver(id, false, showTitle)}
            >
                <Box pad="xsmall" direction="row" gap="small" background={props.bgColor} round="small">
                    <props.icon size={props.size} color={props.color} />
                </Box>
            </Button>
            {btnRef.current && id && showTitle && (
                <Drop
                    align={{ left: props.titleAlign === "right" ? "right" : undefined, top: props.titleAlign === "bottom" ? "bottom" : undefined }}
                    target={btnRef.current}
                    trapFocus={false}
                    plain
                >
                    <Box pad="xsmall" background="status-unknown">
                        <Text>{props.title}</Text>
                    </Box>
                </Drop>
            )}
        </React.Fragment>
    )
}

export default connector(IconOnlyButton);