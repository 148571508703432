import moment from 'moment';

declare global {
    interface Date {
        display(): string;
        ago(): string;
    }
}

// todo: fix it
// eslint-disable-next-line no-extend-native
Date.prototype.display = function () {
    return moment(this).format("LLL");
}

// todo: fix it
// eslint-disable-next-line no-extend-native
Date.prototype.ago = function () {
    return moment(this).fromNow(true);
}