import React, { useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { AddCircle, Close } from 'grommet-icons';

import { AppState } from '../../store';
import { setCurrentNote, createNote } from '../../store/notes/actions';
import NoteForm from './NoteForm';
import { FullNote } from '../../store/notes/types';
import Loading from '../common/Loading';


const mapState = ({ note }: AppState) => ({
    loadingState: note.currentLoadingState
});

const connector = connect(mapState);
type AllProps = ConnectedProps<typeof connector>

const CreateNote = (props: AllProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.loadingState !== "LOADING") {
            const note: FullNote = {
                id: '',
                title: '',
                text: '',
                files: [],
                filesToDeleteOnUpdate: [],
                createdOn: new Date(),
                favorite: false
            }
            dispatch(setCurrentNote(note));
        }
    })

    if (props.loadingState === "LOADING") {
        return <Loading />
    }

    return (
        <NoteForm
            confirmIcon={AddCircle}
            cancelIcon={Close}
            confirmText="Create"
            onSubmit={createNote}    
        />
    )
}

export default connector(CreateNote);