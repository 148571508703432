import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Sidebar, Nav, Text } from 'grommet';
import { Projects, Alert, Copy, AddCircle, Logout, Configure, Refresh } from "grommet-icons";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import UserMenu from './UserMenu';
import { AppState } from "../../store";
import { getApiUrl } from "../../grpc/helpers";
import IconOnlyButton from "../common/IconOnlyButton";
import { UserSession } from "../../store/sessions/types";
import { purgeLoadedState, signOutUser } from "../../store/common/actions";

const mapState = ({ session }: AppState) => ({
    session: session.current
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        signOut: (session: UserSession) => {
            dispatch(signOutUser(session));
        },
        refreshAll: () => {
            dispatch(purgeLoadedState());
        }
    }
}

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>

const AppBar = (props: AllProps) => {
    let navMenu;
    if (props.session.authenticated) {
        navMenu = (
            <Nav gap="small" align="center">
                <IconOnlyButton icon={Projects} title="Dashboard" titleAlign="right" idKey="app-menu-dashboard" href="/" />
                <IconOnlyButton icon={Copy} title="Notes" titleAlign="right" idKey="app-menu-notes" href="/notes" />
                <IconOnlyButton icon={AddCircle} title="Create note" titleAlign="right" idKey="app-menu-create-note" href="/note/create" />
            </Nav>
        )
    } else {
        navMenu = (
            <Nav align="center">
                <a href={getApiUrl()} target="_blank" rel="noopener noreferrer">
                    <IconOnlyButton icon={Alert} title="Trust API certificate" titleAlign="right" idKey="app-menu-trust-cert" color="status-warning" />
                </a>
            </Nav>
        )
    }

    let footer = <React.Fragment />;
    if (props.session.authenticated) {
        footer = (
            <Nav gap="small" align="center">
                <IconOnlyButton icon={Refresh} title="Refresh All" titleAlign="right" idKey="app-menu-refresh-all" onClick={props.refreshAll} />
                <IconOnlyButton icon={Configure} title="Settings" titleAlign="right" idKey="app-menu-settings" /> { /* todo: onClick? */}
                <IconOnlyButton icon={Logout} color="status-warning" titleAlign="right" title="Log out" idKey="app-menu-log-out" onClick={() => props.signOut(props.session)} />
            </Nav>
        )
    }

    const header = (
        <React.Fragment>
            <UserMenu />
            <Text size="xsmall" margin="small" textAlign="center" alignSelf="center" color="accent-3" weight="bold">CryptoVault</Text>
        </React.Fragment>
    )

    return (
        <Sidebar background="brand" header={header} footer={footer} align="center">
            {navMenu}
        </Sidebar>
    )
}

export default connector(AppBar);