export const COMMON_ERROR = "@@COMMON/ERROR";
export const CLOSE_ERROR = "@@COMMON/CLOSE_ERROR";
export const SIGN_OUT_COMPLETE = "@@COMMON/SIGN_OUT_COMPLETE"
export const PURGE_STATE = "@@COMMON/PURGE_STATE";
export const BUTTON_OVER = "@@COMMON/BUTTON_OVER"
export const SET_VIEW_SIZE = "@@COMMON/SET_VIEW_SIZE";
export const SET_MENU_OPENED = "@@COMMON/SET_MENU_OPENED";

export const GENERIC_ERROR_CODE = 10000;
export const WRONG_CREDS_ERROR_CODE = 10001;

export type LoadingState = "INIT" | "LOADING" | "LOADED" | "ERROR";

export interface CommonState {
    readonly errors: ErrorData[];
    readonly buttonOver: Map<string, boolean>;
    readonly viewSize: ViewSize;
    readonly mobileMenuOpened: boolean;
}

export declare type ViewSize = "small" | "medium" | "large";

export interface ErrorData {
    code: number;
    message: string;
}

interface SignOutComplete {
    type: typeof SIGN_OUT_COMPLETE;
}

interface ErrorOccurredAction {
    type: typeof COMMON_ERROR;
    error: ErrorData;
}

interface CloseErrorAction {
    type: typeof CLOSE_ERROR;
    error: ErrorData;
}

interface PurgeStateAction {
    type: typeof PURGE_STATE;
}

interface ButtonOverAction {
    type: typeof BUTTON_OVER;
    id: string;
    over: boolean;
}

interface SetViewSizeAction {
    type: typeof SET_VIEW_SIZE;
    size: string;
}

interface SetMenuOpened {
    type: typeof SET_MENU_OPENED;
    opened: boolean;
}

export type CommonActionTypes = 
    ErrorOccurredAction 
    | CloseErrorAction
    | SignOutComplete
    | PurgeStateAction
    | ButtonOverAction
    | SetViewSizeAction
    | SetMenuOpened;