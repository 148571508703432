import React, { MouseEventHandler } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Header, Heading, Box, DropButton } from 'grommet';
import { Projects, Copy, AddCircle, Logout, Configure, Refresh, Menu, Login, Icon } from "grommet-icons";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import UserMenu from './UserMenu';
import { AppState } from "../../store";
import IconLabelButton from "../common/IconLabelButton";
import { UserSession } from "../../store/sessions/types";
import { purgeLoadedState, signOutUser, setMenuOpened } from "../../store/common/actions";

const mapState = ({ session, common }: AppState) => ({
    session: session.current,
    menuOpened: common.mobileMenuOpened
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        signOut: (session: UserSession) => {
            dispatch(signOutUser(session));
        },
        refreshAll: () => {
            dispatch(purgeLoadedState());
        },
        setMenuOpened: (opened: boolean) => {
            dispatch(setMenuOpened(opened));
        }
    }
}

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>

const AppBarMobile = (props: AllProps) => {
    const menuClosed = () => props.setMenuOpened(false);
    const menuOpened = () => props.setMenuOpened(true);
    return (
        <Header background="brand" pad="xsmall">
            <DropButton 
                icon={<Menu />} 
                dropAlign={{ top: 'bottom', right: 'right' }} 
                dropContent={<AppBarMenu props={props} onClose={menuClosed} />} 
                open={props.menuOpened}
                onOpen={menuOpened}
                onClose={menuClosed} 
            />
            <Heading level="3" margin={{ horizontal: "large", vertical: "medium" }}>CryptoVault</Heading>
            <UserMenu />
        </Header>
    )
}

const AppBarMenu = ({ props, onClose }: { props: AllProps, onClose: () => void }) => {
    const boxProps = {
        width: "large",
        background: "light-1",
        gap: "medium",
        pad: "medium",
        onClick: onClose
    };

    if (props.session.authenticated) {
        return (
            <Box {...boxProps}>
                <AppBarMenuItem icon={Projects} href="/" label="Dashboard" />
                <AppBarMenuItem icon={Copy} href="/notes" label="Notes" />
                <AppBarMenuItem icon={AddCircle} href="/note/create" label="Create Note" />

                <Box border={{ color: "black", style: "solid", side: "bottom" }}></Box>

                <AppBarMenuItem icon={Refresh} onClick={props.refreshAll} label="Refresh All" />
                <AppBarMenuItem icon={Configure} onClick={() => { }} label="Settings" />
                <AppBarMenuItem icon={Logout} onClick={() => props.signOut(props.session)} label="Log out" bgColor="status-warning" />
            </Box>
        )
    }

    return (
        <Box {...boxProps}>
            <AppBarMenuItem icon={Login} href="/signin" label="Log in" />
        </Box>
    )
}

interface AppBarMenuItemProps {
    icon: Icon;
    label: string;
    bgColor?: string;
    href?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

const AppBarMenuItem = (props: AppBarMenuItemProps) => {
    return <IconLabelButton {...props} fill="horizontal" propagateClick={true} />
}

export default connector(AppBarMobile);