import { grpc } from "@improbable-eng/grpc-web";
import { Dispatch } from "redux";

import { CommonActionTypes, COMMON_ERROR } from "../store/common/types";
import { UserSession } from "../store/sessions/types";
import { inDevMode } from "../common";

type ServiceError = { message: string, code: number; metadata: grpc.Metadata }

function createClient<TClient>(factory: (endpoint: string) => TClient): TClient {
    return factory(getApiUrl());
}

function buildMetadata(session: UserSession): grpc.Metadata {
    return new grpc.Metadata({ 'cvkey': session.id });
}

interface GrpcCallFn<TRequest, TResponse> {
    (request: TRequest, metadata: grpc.Metadata, callback: (error: ServiceError | null, response: TResponse | null) => void): void;
}

interface SessionlessGrpcCallFn<TRequest, TResponse> {
    (request: TRequest, callback: (error: ServiceError | null, response: TResponse | null) => void): void;
}

export function getApiUrl() {
    return inDevMode() ? `https://${window.location.hostname}:5001` : `https://${window.location.host}/api`
}

export class GrpcClient<TClient> {
    private readonly client: TClient;

    constructor(clientType: { new(url: string): TClient }) {
        this.client = createClient(x => new clientType(x));
    }

    unaryCall<TRequest, TResponse>(
        actionAccessor: (client: TClient) => GrpcCallFn<TRequest, TResponse>, 
        request: TRequest, 
        session: UserSession, 
        dispatch: Dispatch<CommonActionTypes>
    ) {
        const metadata = buildMetadata(session);
        const action = actionAccessor(this.client);

        return new Promise<TResponse>((resolve) => {
            action.call(this.client, request, metadata, (err, response) => {
                if (err) {
                    console.error("on calling gRPC", action.name, "Error:", err.code, err.message);
                    dispatch({ type: COMMON_ERROR, error: err });
                }
    
                if (response) {
                    resolve(response);
                }
            });
        });
    }

    unaryCallNoSession<TRequest, TResponse>(
        actionAccessor: (client: TClient) => SessionlessGrpcCallFn<TRequest, TResponse>, 
        request: TRequest, 
        dispatch: Dispatch<CommonActionTypes>
    ) {
        const action = actionAccessor(this.client);

        return new Promise<TResponse>((resolve) => {
            action.call(this.client, request, (err, response) => {
                if (err) {
                    console.error("on calling gRPC", action.name, "Error:", err.code, err.message);
                    dispatch({ type: COMMON_ERROR, error: err });
                }
    
                if (response) {
                    resolve(response);
                }
            });
        });
    }
}