import React, { MouseEventHandler } from "react";
import { Button } from "grommet";

interface TagsProps {
    tag: string;
    onClick?: (tag: string) => void;
}

const Tag = (props: TagsProps) => {
    const text = "#" + props.tag;
    if (props.onClick) {
        const clickHandler: MouseEventHandler<HTMLButtonElement> = e => {
            e.preventDefault();
            e.stopPropagation();
            props.onClick!(props.tag);
        } 

        return <Button plain hoverIndicator color="brand" size="small" label={text} onClick={clickHandler} />
    }
    
    return <Button plain color="brand" size="small" label={text} style={{cursor: "default"}} />
}

export default Tag;

interface TextAndTags {
    text: string;
    tags: string[];
}

export function splitTags(text: string): TextAndTags {
    function cutTag(text: string, tagBegin: number, tagEnd: number) {
        let result = "";
        // get text before tag
        if (tagBegin > 0) {
            result = text.substring(0, tagBegin).trim();
        }

        // get text after tag
        if (tagEnd > 0) {
            result += text.substring(tagEnd);
        }

        return result;
    }

    function trimChar(text: string, char: string): string {
        let result = text;

        // remove from start
        while (result.charAt(0) === char) {
            result = result.substring(1);
        }

        // remove from end
        while (result.charAt(result.length - 1) === char) {
            result = result.substring(0, result.length - 2);
        }

        return result;
    }

    let textResult = text;
    const tags = [];

    while (true) {
        const hashIndex = textResult.indexOf('#');
        if (hashIndex >= 0) {
            const tagEnd = textResult.indexOf(' ', hashIndex);
            let tag = tagEnd > 0 ? textResult.substring(hashIndex + 1, tagEnd) : textResult.substring(hashIndex + 1);
            tag = trimChar(tag, "#");
            if (tag) {
                tags.push(tag);
            }

            textResult = cutTag(textResult, hashIndex, tagEnd).trim();
        } else {
            return { text: textResult, tags: tags };
        }
    }
}