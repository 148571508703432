import React from 'react';
import { Form, FormField, TextInput, Box, Button } from 'grommet';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AppState } from '../store';
import { signInInfoChanged, signInUser } from '../store/sessions/actions';


const mapState = ({ session }: AppState) => ({
    credentials: session.credentials,
    loadingState: session.loadingState,
    authenticated: session.current.authenticated,
    error: session.error
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        dataChanged: (newValue : any) => { // todo: should not be any
            dispatch(signInInfoChanged(newValue));
        },
        signIn: (value: any) => { // todo: should not be any
            dispatch(signInUser(value));
        }
    }
}

const connector = connect(mapState, mapDispatch)

type AllProps = ConnectedProps<typeof connector>

const SignIn = (props: AllProps) => { 
    if (props.authenticated) {
        return <Redirect to={{pathname: "/"}} />
    }

    const disabled = props.loadingState === "LOADING";
    return (
        <Box direction="row" align="center" justify="center" flex>
            <Form 
                value={props.credentials} 
                onChange={props.dataChanged}
                onSubmit={({ value }) => props.signIn(value)}
                validate="blur"
            >
                <FormField label="Username" name="login" disabled={disabled}>
                    <TextInput name="login" placeholder="Your username ..." />
                </FormField>
                <FormField label="Password" name="password" required disabled={disabled}>
                    <TextInput name="password" type="password" placeholder="Your password ..." />
                </FormField>
                <Box direction="row" gap="medium">
                    <Button type="submit" primary label="Sign In" disabled={disabled} />
                </Box>
            </Form>
        </Box>
    )
}

export default connector(SignIn)