import React from "react";
import Loader from "react-spinners/PuffLoader";
import { Box } from "grommet";

interface LoadingProps {
    inline?: boolean;
}

const Loading = (props: LoadingProps) => {
    if (props.inline) {
        return <Loader size={50} color={"#00739D"} loading={true} />
    }

    return (
        <Box fill="horizontal" align="center" margin={{vertical: "large"}}>
            <Loader size={100} color={"#00739D"} loading={true} />
        </Box>
    )
}

export default Loading;