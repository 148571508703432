import React from "react";
import { Layer, Box, Text } from "grommet";
import { Close, Alert } from "grommet-icons";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect, ConnectedProps } from "react-redux";

import { AppState } from "../../store";
import IconOnlyButton from "../common/IconOnlyButton";
import { ErrorData } from "../../store/common/types";
import { closeErrorMessage } from "../../store/common/actions";

const mapState = ({ common }: AppState) => ({ 
    errors: common.errors
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        close: (error: ErrorData) => {
            dispatch(closeErrorMessage(error));
        }
    }
}

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>

const ErrorsNotification = (props: AllProps) => {
    return (
    <React.Fragment>
        {props.errors.map((x, i) => (
            <Layer 
                key={i} 
                position="bottom" 
                modal={false} 
                margin={{vertical: "medium", horizontal: "small"}} 
                responsive={false} 
                plain>

                <Box 
                    align="center" 
                    direction="row" 
                    gap="xsmall" 
                    justify="between" 
                    round="medium" 
                    elevation="medium" 
                    pad={{ vertical: 'xsmall', horizontal: 'small' }}
                    background="status-error">

                    <Alert />
                    <Text>{x.message}</Text>
                    <IconOnlyButton icon={Close} size="small" onClick={() => props.close(x)} />
                </Box>
            </Layer>
        ))}
    </React.Fragment>);
}

export default connector(ErrorsNotification);