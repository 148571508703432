import React from "react";
import { useDispatch } from "react-redux";
import { Box, ResponsiveContext } from 'grommet';

import AutoSignOutDialog from "./AutoSignOutDialog";
import ErrorsNotification from "./ErrorsNotification";
import AppBar from "./AppBar";
import AppBarMobile from "./AppBarMobile";
import Routes from "../../routers";
import { setViewSize } from '../../store/common/actions';

const MainView = () => {
    const dispatch = useDispatch();

    // set view size
    const size = React.useContext(ResponsiveContext);
    dispatch(setViewSize(size));

    const isSmall = size === "small";
    return (
        <Box direction={isSmall ? "column" : "row"} fill>
            <AutoSignOutDialog />
            <ErrorsNotification />

            {isSmall && <AppBarMobile />}
            {!isSmall && <AppBar />}
            <Box pad="small" fill>
                <Routes />
            </Box>
        </Box>
    )
}

export default MainView;
