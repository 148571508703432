import React from "react";
import { CardHeader, Heading, Card, CardBody, Text, CardFooter } from "grommet";
import { Hide } from "grommet-icons";
import { useHistory } from "react-router-dom";
import pluralize from "pluralize";

import { DashboardCardData } from "../../store/dashboard/types";
import NoteText from "../common/NoteText";
import IconLabelButton from "../common/IconLabelButton";
import Tag, { splitTags } from "../common/Tag";

interface DashboardCardProps {
    card: DashboardCardData;
    onRemove: (card: DashboardCardData) => void;
}

const DashboardCard = (props: DashboardCardProps) => {
    const history = useHistory();

    const filesText = props.card.fileCount > 0 
        ? <Text weight="bold">{pluralize("file", props.card.fileCount, true)}</Text>
        : <Text color="dark-6">no files</Text>;
    
    const title = splitTags(props.card.title);
    return (
        <Card width="medium" height="medium" margin="small" background="light-1">
            <CardHeader pad="small" background="light-3" justify="start" onClick={() => history.push(`/note/${props.card.id}/view`)}>
                <Heading level="3" margin="none">{title.text}</Heading>
                {title.tags.map((x, i) => (
                    <Tag key={i} tag={x} />
                ))}
            </CardHeader>
            <CardBody>
                <NoteText text={props.card.text} />
            </CardBody>
            <CardFooter background="light-5" justify="between" pad={{horizontal: "small", vertical: "xxsmall"}}>
                {filesText}
                <IconLabelButton icon={Hide} label="Hide" color="red" onClick={() => props.onRemove(props.card)} />
            </CardFooter>
        </Card>
    )
}

export default DashboardCard;