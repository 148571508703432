import React, { useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Box } from 'grommet';
import { Edit, List, Trash, Link, BlockQuote, Copy } from 'grommet-icons';
import { RouteComponentProps, useHistory } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import showdown from 'showdown';

import { AppState } from '../../store';
import { getNote, showDeleteNoteDialog, showQRCode } from '../../store/notes/actions';
import IconLabelButton from '../common/IconLabelButton';
import Loading from '../common/Loading';
import DeleteNoteDialog from './DeleteNoteDialog';
import NotePreview from './NotePreview';
import QRCodeDialog from './QRCodeDialog';
import '../../dateExtensions';
import { FullNote } from '../../store/notes/types';
import { removeHtmlTags } from '../../common';

const converter = new showdown.Converter();
const convertMarkdownToText = (md: string): string => {
    const htmlText = converter.makeHtml(md);
    return removeHtmlTags(htmlText)
}

const mapState = ({ note, session }: AppState) => ({
    note: note.current,
    loadingState: note.currentLoadingState,
    session: session.current,
    noteToDelete: note.noteToDelete
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        showDeleteDialog: (note: any) => {
            dispatch(showDeleteNoteDialog(note));
        },
        showLinkToNote: () => {
            dispatch(showQRCode("Link to note", window.location.href));
        },
        showTextQr: (note: FullNote) => {
            const text = convertMarkdownToText(note.text);
            dispatch(showQRCode("Note text", text));
        }
    }
};

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>

interface RouteParams {
    id: string;
}

const ViewNote = (props: AllProps & RouteComponentProps<RouteParams>) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const noteId = props.match.params.id;
        if (props.loadingState === "INIT" || props.note.id !== noteId) {
            dispatch(getNote(noteId, props.session));
        }
    })

    if (props.loadingState === "LOADING" || props.loadingState === "INIT") {
        return <Loading />
    }

    const copyTextToClipboard = async () => {
        const text = convertMarkdownToText(props.note.text);
        await navigator.clipboard.writeText(text);

        console.log("copied to clipboard", text);
        // todo: show notification
    }
    
    const noteId = props.match.params.id;
    const footer = (
        <Box key="buttons" direction="row" justify="between" gap="medium" fill>
            <Box direction="row" gap="small" wrap>
                <IconLabelButton icon={List} label="Back" onClick={() => history.push('/notes')} />
                <IconLabelButton icon={Edit} label="Edit" href={`/note/${noteId}/edit`} />
                <IconLabelButton icon={Link} label="Link to QR" onClick={props.showLinkToNote} />
                <IconLabelButton icon={BlockQuote} label="Text to QR" onClick={() => props.showTextQr(props.note)} />
                <IconLabelButton icon={Copy} label="Copy text" onClick={copyTextToClipboard} disabled={navigator.clipboard === undefined} />
            </Box>
            <Box>
                <IconLabelButton icon={Trash} label="Delete" color="red" onClick={() => props.showDeleteDialog(props.note)} />
            </Box>
        </Box>
    )

    return (
        <React.Fragment>
            <NotePreview footer={footer} />
            <QRCodeDialog />
            <DeleteNoteDialog />
        </React.Fragment>
    )
}

export default connector(ViewNote);