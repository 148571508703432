import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Heading, Main, Paragraph, Text } from 'grommet';
import { Link } from 'react-router-dom';

import { AppState } from '../store';
import Dashboard from "./dashboard/Dashboard";

const mapState = ({ session }: AppState) => ({
    authenticated: session.current.authenticated
});

const connector = connect(mapState);
type AllProps = ConnectedProps<typeof connector>;

const Home = (props: AllProps) => {
    if (props.authenticated) {
        return <Dashboard />
    }

    return (
        <Main align="center">
            <Heading>Hello, friend</Heading>
            <Paragraph>To work with Vault <Text weight="bold">@username</Text> please <Link to="/signin">sign in</Link></Paragraph>
        </Main>
    )
}

export default connector(Home);