import React from "react";
import { Markdown } from "grommet";

interface NoteTextProps {
    text: string;
}

const NoteText = (props: NoteTextProps) => {
    const styleOverride = {
        'a': {
            'component': "a",
            'props': { 'target': "_blank" }
        },
        'p': {
            'component': "p"
        }
    };

    return <Markdown components={styleOverride}>{props.text}</Markdown>
}

export default NoteText;