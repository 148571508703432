import Crypto from "crypto-js";
import Base64 from "base64-arraybuffer";
import { UserCredentials } from "../store/sessions/types";
import pako from "pako";
import filesize from "filesize";

export function encrypt(text: string, key: string) {
    if (!key) throw new Error("Key should not be empty");

    const encrypted = Crypto.Rabbit.encrypt(text, key);
    return encrypted.toString();
}

export function encryptFile(base64Text: string, key: string, log = true) {
    if (!key) throw new Error("Key should not be empty");

    const headerSeparatorIndex = base64Text.indexOf(",");
    const base64Content = base64Text.substring(headerSeparatorIndex + 1);
    const rawContent = Base64.decode(base64Content);
    const rawContentArray = new Uint8Array(rawContent);
    const rawContentWordArray = convertUint8ArrayToWordArray(rawContentArray);
    const encrypted = Crypto.Rabbit.encrypt(rawContentWordArray, key).toString(Crypto.format.OpenSSL);
    const result = pako.deflate(encrypted, { level: 9 });

    if (log) {
        console.log("src size", filesize(base64Content.length));
        console.log("raw size", filesize(rawContentArray.length));
        console.log("encrypted size", filesize(encrypted.length));
        console.log("result size", filesize(result.length));
    }

    return result;
}

export function encryptFileList(ids: (string | undefined)[], key: string) {
    return encrypt(ids.filter(x => x).join('#'), key);
}

export function decrypt(text: string, key: string) {
    if (!key) throw new Error("Key should not be empty");

    const decrypted = Crypto.Rabbit.decrypt(text, key);
    return decrypted.toString(Crypto.enc.Utf8);
}

export function decryptFile(bytes: Uint8Array, key: string, fileSize: number) {
    if (!key) throw new Error("Key should not be empty");

    const encrypted = pako.inflate(bytes, { to: "string" });
    const decrypted = Crypto.Rabbit.decrypt(encrypted, key, { format: Crypto.format.OpenSSL });
    const rawContent = convertWordArrayToUint8Array(decrypted, fileSize);

    return "data:image/png;base64," + Base64.encode(rawContent); // todo: may be not PNG!
}

export function decryptFileList(text: string, key: string) {
    return decrypt(text, key).split('#').filter(x => x);
}

export function getCryptoKey(creds: UserCredentials) {
    return Crypto.SHA512(creds.password + creds.login).toString(Crypto.enc.Base64);
}

export function randomKey() {
    return Crypto.SHA512(Math.random().toString()).toString(Crypto.enc.Hex).substring(0, 10);
}

function convertWordArrayToUint8Array(wordArray: Crypto.lib.WordArray, size: number) {
	const len = wordArray.words.length;
    const u8_array = new Uint8Array(size);
        
	let	offset = 0;
	for (let i=0; i<len; i++) {
        const word = wordArray.words[i];
        u8_array[offset++] = word >> 24;
        u8_array[offset++] = (word >> 16) & 0xff;
        u8_array[offset++] = (word >> 8) & 0xff;
		u8_array[offset++] = word & 0xff;
	}
	return u8_array;
} 

function convertUint8ArrayToWordArray(u8Array: Uint8Array) {
    const words = [];
    let i = 0; 
    const len = u8Array.length;

	while (i < len) {
		words.push(
			(u8Array[i++] << 24) |
			(u8Array[i++] << 16) |
			(u8Array[i++] << 8)  |
			(u8Array[i++])
		);
	}

    return Crypto.lib.WordArray.create(words, words.length * 4);
}