import { CommonActionTypes, LoadingState } from "../common/types";

export const GET_CURRENT_SESSION_REQUEST = "@@SESSION/GET_CURRENT_SESSION_REQUEST";
export const SIGN_IN_REQUEST = "@@SESSION/SIGN_IN_REQUEST";
export const SET_CURRENT_SESSION = "@@SESSION/SET_CURRENT_SESSION";
export const SIGN_DATA_CHANGED = "@@SESSION/SIGN_DATA_CHANGED";
export const SIGN_OUT_DIALOG = "@@SESSION/SIGN_OUT_DIALOG";
export const PROLONG_SESSION = "@@SESSION/PROLONG_SESSION"

export interface UserSession {
    id: string;
    name: string;
    key: string;
    expiresOn: Date;
    authenticated: boolean;
}

export interface UserCredentials {
    login: string;
    password: string;
}

export interface SessionState {
    readonly loadingState: LoadingState;
    readonly current: UserSession;
    readonly credentials: UserCredentials;
    readonly showSessionEndDialog: boolean;
    readonly error?: string;
}

interface GetCurrentSessionAction {
    type: typeof GET_CURRENT_SESSION_REQUEST;
}

interface SetCurrentSessionAction {
    type: typeof SET_CURRENT_SESSION;
    session: UserSession;
    prolonged?: boolean;
}

interface StartNewSessionAction {
    type: typeof SIGN_IN_REQUEST;
}

interface SignInDataChangeAction {
    type: typeof SIGN_DATA_CHANGED;
    data: UserCredentials;
}

interface ShowHideSignOutDialogAction {
    type: typeof SIGN_OUT_DIALOG;
    show: boolean;
}

interface ProlongSessionAction {
    type: typeof PROLONG_SESSION;
    expiresOn: Date;
}

export type SessionActionTypes = 
    CommonActionTypes 
    | GetCurrentSessionAction 
    | SetCurrentSessionAction 
    | StartNewSessionAction
    | SignInDataChangeAction
    | ShowHideSignOutDialogAction
    | ProlongSessionAction