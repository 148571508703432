import React from "react";
import { Box, Image, Text, Button, CardBody, Card, CardHeader, Heading, CardFooter } from "grommet";
import { Document, Download, Star } from "grommet-icons";
import filesize from "filesize";
import { saveAs } from "file-saver";

import { AppState } from "../../store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { FileData, FileDataType } from "../../store/notes/types";
import { downloadFile } from "../../store/notes/actions";
import IconOnlyButton from "../common/IconOnlyButton";
import NoteText from "../common/NoteText";
import { UserSession } from "../../store/sessions/types";
import Tag, { splitTags } from "../common/Tag";

interface FilePreviewProps {
    file: FileData;
}

const ImagePreview = (props: FilePreviewProps) => {
    const showImage = () => {
        const image = new window.Image();
        image.src = props.file.content!;
        const previewWindow = window.open("");
        previewWindow?.document?.write(image.outerHTML);
    }

    const title = `name: ${props.file.name}, size: ${filesize(props.file.size)}`;
    return (
        <Button as="a" onClick={showImage} title={title} plain>
            <Box height={{ max: "medium" }}>
                <Image fit="contain" src={props.file.content} />
            </Box>
        </Button>
    )
}

interface OtherFilePreviewProps extends FilePreviewProps {
    session: UserSession;
}

const OtherFilePreview = (props: OtherFilePreviewProps) => {
    const dispatch = useDispatch();

    const showFile = () => {
        if (!props.file.content) {
            dispatch(downloadFile(props.file.id, props.session, (x: FileData) => {
                saveAs(x.content!, x.name);
            }));
        } else {
            saveAs(props.file.content, props.file.name);
        }
    }

    return (
        <Box direction="row" height="xxsmall" justify="between" align="center" background="neutral-3" pad="small">
            <Box direction="row" basis="2/3">
                <Document />
                <Text weight="bold" margin={{ left: "small" }} truncate wordBreak="keep-all">{props.file.name}</Text>
            </Box>
            <Box direction="row" align="center">
                <Text margin={{ right: "small" }}>{filesize(props.file.size)}</Text>
                <IconOnlyButton icon={Download} onClick={showFile} />
            </Box>
        </Box>
    )
}

const mapState = ({ note, session }: AppState) => ({
    note: note.current!,
    session: session.current
});

const connector = connect(mapState);
type AllProps = ConnectedProps<typeof connector>

interface NotePreviewProps {
    footer?: JSX.Element;
}

const NotePreview = (props: AllProps & NotePreviewProps) => {
    const title = splitTags(props.note.title);
    return (
        <Card width={{ min: "medium" }}  alignSelf="center" background="light-1" margin={{ horizontal: "large", vertical: "small" }}>
            <CardHeader pad="small" background="light-3">
                <Box direction="row" gap="small">
                    {props.note.favorite && <Star color="accent-4" />}
                    <Heading level="3" margin="none">{title.text}</Heading>
                    {title.tags.map((x, i) => (
                        <Tag key={i} tag={x} />
                    ))}
                </Box>
                <Text size="small">{props.note.createdOn.display()}</Text>
            </CardHeader>
            <CardBody pad="small" overflow="auto">
                <NoteText text={props.note.text} />
                <Box alignContent="start" style={{ minHeight: "auto" }} margin={{ top: "medium" }}>
                    {props.note.files.filter(x => x.type === FileDataType.Image).map(x => (
                        <Box key={x.key} margin={{ bottom: "xsmall" }}>
                            <ImagePreview file={x} />
                        </Box>
                    ))}
                    {props.note.files.filter(x => x.type !== FileDataType.Image).map(x => (
                        <Box key={x.key} margin={{ bottom: "xsmall" }}>
                            <OtherFilePreview file={x} session={props.session} />
                        </Box>
                    ))}
                </Box>
            </CardBody>
            {props.footer && <CardFooter pad="small" background="light-5" justify="start" children={props.footer} />}
        </Card>
    )
}

export default connector(NotePreview);