import { Dispatch } from "redux";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

import { AppThunk } from "../common/actions";
import { UserSession } from "../sessions/types";
import { GrpcClient } from "../../grpc/helpers";
import { ADD_CARD, CARD_ADDED, CARD_REMOVED, DashboardActionTypes, DashboardCardData, GET_DASHBOARD, REMOVE_CARD, SET_DASHBOARD } from "./types";
import { DashboardClient } from "../../grpc/generated/dashboard_pb_service";
import { DashboardResponse } from "../../grpc/generated/dashboard_pb";
import { decrypt, decryptFileList } from "../../crypto";
import { ShortNote } from "../notes/types";
import { IdMessage } from "../../grpc/generated/common_pb";

const client = new GrpcClient(DashboardClient);

export const getDashboard : AppThunk = (session: UserSession) => {
    return async (dispatch: Dispatch<DashboardActionTypes>) => {
        dispatch({ type: GET_DASHBOARD });

        const dashboard = await client.unaryCall<Empty, DashboardResponse>(x => x.get, new Empty(), session, dispatch);
        const cards: DashboardCardData[] = dashboard.getCardsList().map(x => ({ 
            id: x.getId(), 
            title: decrypt(x.getTitle_asB64(), session.key),
            text: decrypt(x.getText_asB64(), session.key),
            fileCount: decryptFileList(x.getFiles_asB64(), session.key).length
        }));
        
        dispatch({ type: SET_DASHBOARD, data: cards });
    }
}

export const addCard : AppThunk = (note: ShortNote, session: UserSession) => {
    return async (dispatch: Dispatch<DashboardActionTypes>) => { 
        dispatch({ type: ADD_CARD, data: note });

        const noteId = new IdMessage();
        noteId.setId(note.id);

        const response = await client.unaryCall<IdMessage, DashboardResponse.Card>(x => x.addCard, noteId, session, dispatch);
        const card: DashboardCardData = {
            id: response.getId(),
            title: decrypt(response.getTitle_asB64(), session.key),
            text: decrypt(response.getText_asB64(), session.key),
            fileCount: decryptFileList(response.getFiles_asB64(), session.key).length
        };

        dispatch({ type: CARD_ADDED, data: card });
    }
}

export const removeCard : AppThunk = (card: DashboardCardData, session: UserSession) => {
    return async (dispatch: Dispatch<DashboardActionTypes>) => { 
        dispatch({ type: REMOVE_CARD, data: card });

        const cardId = new IdMessage();
        cardId.setId(card.id);

        await client.unaryCall(x => x.removeCard, cardId, session, dispatch);

        dispatch({ type: CARD_REMOVED, data: card });
    }
}