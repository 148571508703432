import React from "react";
import { Route, RouteProps, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from './components/Home';
import SignIn from './components/SignIn';

import NotesList from './components/notes/NotesList';
import CreateNote from './components/notes/CreateNote';
import ViewNote from './components/notes/ViewNote';
import EditNote from './components/notes/EditNote';

import { AppState } from "./store";
import { inDevMode } from "./common";

const Router = () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/signin" component={SignIn} />
        <PrivateRoute exact path="/notes" component={NotesList} />
        <PrivateRoute exact path="/note/create" component={CreateNote} />
        <PrivateRoute exact path="/note/:id/view" component={ViewNote} />
        <PrivateRoute exact path="/note/:id/edit" component={EditNote} />
    </Switch>
)

const PrivateRoute = ({ component, ...rest }: RouteProps) => {
    const authenticated = useSelector((state: AppState) => state.session.current.authenticated);
    
    if (authenticated) {
        return <Route {...rest} component={component} />
    }

    if (inDevMode()) {
        return <Route {...rest} render={x => <React.Fragment />} />
    }

    return <Route {...rest} render={x => (<Redirect to={{pathname: "/signin"}} />)} />
}


export default Router;