/* eslint-disable */
// source: note.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.note.CreateNoteRequest', null, global);
goog.exportSymbol('proto.note.FullNoteInfo', null, global);
goog.exportSymbol('proto.note.GetNoteListRequest', null, global);
goog.exportSymbol('proto.note.NoteList', null, global);
goog.exportSymbol('proto.note.ShortNoteInfo', null, global);
goog.exportSymbol('proto.note.UpdateNoteRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.note.GetNoteListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.note.GetNoteListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.note.GetNoteListRequest.displayName = 'proto.note.GetNoteListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.note.NoteList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.note.NoteList.repeatedFields_, null);
};
goog.inherits(proto.note.NoteList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.note.NoteList.displayName = 'proto.note.NoteList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.note.CreateNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.note.CreateNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.note.CreateNoteRequest.displayName = 'proto.note.CreateNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.note.UpdateNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.note.UpdateNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.note.UpdateNoteRequest.displayName = 'proto.note.UpdateNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.note.ShortNoteInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.note.ShortNoteInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.note.ShortNoteInfo.displayName = 'proto.note.ShortNoteInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.note.FullNoteInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.note.FullNoteInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.note.FullNoteInfo.displayName = 'proto.note.FullNoteInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.note.GetNoteListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.note.GetNoteListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.note.GetNoteListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.GetNoteListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    take: jspb.Message.getFieldWithDefault(msg, 1, 0),
    skip: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.note.GetNoteListRequest}
 */
proto.note.GetNoteListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.note.GetNoteListRequest;
  return proto.note.GetNoteListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.note.GetNoteListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.note.GetNoteListRequest}
 */
proto.note.GetNoteListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTake(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.note.GetNoteListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.note.GetNoteListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.note.GetNoteListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.GetNoteListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTake();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSkip();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 take = 1;
 * @return {number}
 */
proto.note.GetNoteListRequest.prototype.getTake = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.note.GetNoteListRequest} returns this
 */
proto.note.GetNoteListRequest.prototype.setTake = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 skip = 2;
 * @return {number}
 */
proto.note.GetNoteListRequest.prototype.getSkip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.note.GetNoteListRequest} returns this
 */
proto.note.GetNoteListRequest.prototype.setSkip = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.note.NoteList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.note.NoteList.prototype.toObject = function(opt_includeInstance) {
  return proto.note.NoteList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.note.NoteList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.NoteList.toObject = function(includeInstance, msg) {
  var f, obj = {
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto.note.ShortNoteInfo.toObject, includeInstance),
    hasmore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.note.NoteList}
 */
proto.note.NoteList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.note.NoteList;
  return proto.note.NoteList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.note.NoteList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.note.NoteList}
 */
proto.note.NoteList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.note.ShortNoteInfo;
      reader.readMessage(value,proto.note.ShortNoteInfo.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasmore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.note.NoteList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.note.NoteList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.note.NoteList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.NoteList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.note.ShortNoteInfo.serializeBinaryToWriter
    );
  }
  f = message.getHasmore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated ShortNoteInfo notes = 1;
 * @return {!Array<!proto.note.ShortNoteInfo>}
 */
proto.note.NoteList.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.note.ShortNoteInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.note.ShortNoteInfo, 1));
};


/**
 * @param {!Array<!proto.note.ShortNoteInfo>} value
 * @return {!proto.note.NoteList} returns this
*/
proto.note.NoteList.prototype.setNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.note.ShortNoteInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.note.ShortNoteInfo}
 */
proto.note.NoteList.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.note.ShortNoteInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.note.NoteList} returns this
 */
proto.note.NoteList.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};


/**
 * optional bool hasMore = 2;
 * @return {boolean}
 */
proto.note.NoteList.prototype.getHasmore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.note.NoteList} returns this
 */
proto.note.NoteList.prototype.setHasmore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.note.CreateNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.note.CreateNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.note.CreateNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.CreateNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: msg.getTitle_asB64(),
    text: msg.getText_asB64(),
    files: msg.getFiles_asB64(),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.note.CreateNoteRequest}
 */
proto.note.CreateNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.note.CreateNoteRequest;
  return proto.note.CreateNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.note.CreateNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.note.CreateNoteRequest}
 */
proto.note.CreateNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFiles(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.note.CreateNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.note.CreateNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.note.CreateNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.CreateNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getText_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getFiles_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getFavorite();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bytes title = 1;
 * @return {!(string|Uint8Array)}
 */
proto.note.CreateNoteRequest.prototype.getTitle = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes title = 1;
 * This is a type-conversion wrapper around `getTitle()`
 * @return {string}
 */
proto.note.CreateNoteRequest.prototype.getTitle_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTitle()));
};


/**
 * optional bytes title = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTitle()`
 * @return {!Uint8Array}
 */
proto.note.CreateNoteRequest.prototype.getTitle_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTitle()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.CreateNoteRequest} returns this
 */
proto.note.CreateNoteRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes text = 2;
 * @return {!(string|Uint8Array)}
 */
proto.note.CreateNoteRequest.prototype.getText = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes text = 2;
 * This is a type-conversion wrapper around `getText()`
 * @return {string}
 */
proto.note.CreateNoteRequest.prototype.getText_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getText()));
};


/**
 * optional bytes text = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getText()`
 * @return {!Uint8Array}
 */
proto.note.CreateNoteRequest.prototype.getText_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getText()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.CreateNoteRequest} returns this
 */
proto.note.CreateNoteRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes files = 3;
 * @return {!(string|Uint8Array)}
 */
proto.note.CreateNoteRequest.prototype.getFiles = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes files = 3;
 * This is a type-conversion wrapper around `getFiles()`
 * @return {string}
 */
proto.note.CreateNoteRequest.prototype.getFiles_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFiles()));
};


/**
 * optional bytes files = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFiles()`
 * @return {!Uint8Array}
 */
proto.note.CreateNoteRequest.prototype.getFiles_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFiles()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.CreateNoteRequest} returns this
 */
proto.note.CreateNoteRequest.prototype.setFiles = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bool favorite = 4;
 * @return {boolean}
 */
proto.note.CreateNoteRequest.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.note.CreateNoteRequest} returns this
 */
proto.note.CreateNoteRequest.prototype.setFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.note.UpdateNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.note.UpdateNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.note.UpdateNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.UpdateNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: msg.getTitle_asB64(),
    text: msg.getText_asB64(),
    files: msg.getFiles_asB64(),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.note.UpdateNoteRequest}
 */
proto.note.UpdateNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.note.UpdateNoteRequest;
  return proto.note.UpdateNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.note.UpdateNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.note.UpdateNoteRequest}
 */
proto.note.UpdateNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFiles(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.note.UpdateNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.note.UpdateNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.note.UpdateNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.UpdateNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getText_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getFiles_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getFavorite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.note.UpdateNoteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.note.UpdateNoteRequest} returns this
 */
proto.note.UpdateNoteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes title = 2;
 * @return {!(string|Uint8Array)}
 */
proto.note.UpdateNoteRequest.prototype.getTitle = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes title = 2;
 * This is a type-conversion wrapper around `getTitle()`
 * @return {string}
 */
proto.note.UpdateNoteRequest.prototype.getTitle_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTitle()));
};


/**
 * optional bytes title = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTitle()`
 * @return {!Uint8Array}
 */
proto.note.UpdateNoteRequest.prototype.getTitle_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTitle()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.UpdateNoteRequest} returns this
 */
proto.note.UpdateNoteRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes text = 3;
 * @return {!(string|Uint8Array)}
 */
proto.note.UpdateNoteRequest.prototype.getText = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes text = 3;
 * This is a type-conversion wrapper around `getText()`
 * @return {string}
 */
proto.note.UpdateNoteRequest.prototype.getText_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getText()));
};


/**
 * optional bytes text = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getText()`
 * @return {!Uint8Array}
 */
proto.note.UpdateNoteRequest.prototype.getText_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getText()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.UpdateNoteRequest} returns this
 */
proto.note.UpdateNoteRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes files = 4;
 * @return {!(string|Uint8Array)}
 */
proto.note.UpdateNoteRequest.prototype.getFiles = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes files = 4;
 * This is a type-conversion wrapper around `getFiles()`
 * @return {string}
 */
proto.note.UpdateNoteRequest.prototype.getFiles_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFiles()));
};


/**
 * optional bytes files = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFiles()`
 * @return {!Uint8Array}
 */
proto.note.UpdateNoteRequest.prototype.getFiles_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFiles()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.UpdateNoteRequest} returns this
 */
proto.note.UpdateNoteRequest.prototype.setFiles = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bool favorite = 5;
 * @return {boolean}
 */
proto.note.UpdateNoteRequest.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.note.UpdateNoteRequest} returns this
 */
proto.note.UpdateNoteRequest.prototype.setFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.note.ShortNoteInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.note.ShortNoteInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.note.ShortNoteInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.ShortNoteInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: msg.getTitle_asB64(),
    files: msg.getFiles_asB64(),
    createdon: (f = msg.getCreatedon()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.note.ShortNoteInfo}
 */
proto.note.ShortNoteInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.note.ShortNoteInfo;
  return proto.note.ShortNoteInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.note.ShortNoteInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.note.ShortNoteInfo}
 */
proto.note.ShortNoteInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFiles(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.note.ShortNoteInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.note.ShortNoteInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.note.ShortNoteInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.ShortNoteInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getFiles_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFavorite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.note.ShortNoteInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.note.ShortNoteInfo} returns this
 */
proto.note.ShortNoteInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes title = 2;
 * @return {!(string|Uint8Array)}
 */
proto.note.ShortNoteInfo.prototype.getTitle = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes title = 2;
 * This is a type-conversion wrapper around `getTitle()`
 * @return {string}
 */
proto.note.ShortNoteInfo.prototype.getTitle_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTitle()));
};


/**
 * optional bytes title = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTitle()`
 * @return {!Uint8Array}
 */
proto.note.ShortNoteInfo.prototype.getTitle_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTitle()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.ShortNoteInfo} returns this
 */
proto.note.ShortNoteInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes files = 3;
 * @return {!(string|Uint8Array)}
 */
proto.note.ShortNoteInfo.prototype.getFiles = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes files = 3;
 * This is a type-conversion wrapper around `getFiles()`
 * @return {string}
 */
proto.note.ShortNoteInfo.prototype.getFiles_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFiles()));
};


/**
 * optional bytes files = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFiles()`
 * @return {!Uint8Array}
 */
proto.note.ShortNoteInfo.prototype.getFiles_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFiles()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.ShortNoteInfo} returns this
 */
proto.note.ShortNoteInfo.prototype.setFiles = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdOn = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.note.ShortNoteInfo.prototype.getCreatedon = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.note.ShortNoteInfo} returns this
*/
proto.note.ShortNoteInfo.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.note.ShortNoteInfo} returns this
 */
proto.note.ShortNoteInfo.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.note.ShortNoteInfo.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool favorite = 5;
 * @return {boolean}
 */
proto.note.ShortNoteInfo.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.note.ShortNoteInfo} returns this
 */
proto.note.ShortNoteInfo.prototype.setFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.note.FullNoteInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.note.FullNoteInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.note.FullNoteInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.FullNoteInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: msg.getTitle_asB64(),
    text: msg.getText_asB64(),
    files: msg.getFiles_asB64(),
    createdon: (f = msg.getCreatedon()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.note.FullNoteInfo}
 */
proto.note.FullNoteInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.note.FullNoteInfo;
  return proto.note.FullNoteInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.note.FullNoteInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.note.FullNoteInfo}
 */
proto.note.FullNoteInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFiles(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.note.FullNoteInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.note.FullNoteInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.note.FullNoteInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.note.FullNoteInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getText_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getFiles_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFavorite();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.note.FullNoteInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.note.FullNoteInfo} returns this
 */
proto.note.FullNoteInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes title = 2;
 * @return {!(string|Uint8Array)}
 */
proto.note.FullNoteInfo.prototype.getTitle = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes title = 2;
 * This is a type-conversion wrapper around `getTitle()`
 * @return {string}
 */
proto.note.FullNoteInfo.prototype.getTitle_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTitle()));
};


/**
 * optional bytes title = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTitle()`
 * @return {!Uint8Array}
 */
proto.note.FullNoteInfo.prototype.getTitle_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTitle()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.FullNoteInfo} returns this
 */
proto.note.FullNoteInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes text = 3;
 * @return {!(string|Uint8Array)}
 */
proto.note.FullNoteInfo.prototype.getText = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes text = 3;
 * This is a type-conversion wrapper around `getText()`
 * @return {string}
 */
proto.note.FullNoteInfo.prototype.getText_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getText()));
};


/**
 * optional bytes text = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getText()`
 * @return {!Uint8Array}
 */
proto.note.FullNoteInfo.prototype.getText_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getText()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.FullNoteInfo} returns this
 */
proto.note.FullNoteInfo.prototype.setText = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes files = 4;
 * @return {!(string|Uint8Array)}
 */
proto.note.FullNoteInfo.prototype.getFiles = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes files = 4;
 * This is a type-conversion wrapper around `getFiles()`
 * @return {string}
 */
proto.note.FullNoteInfo.prototype.getFiles_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFiles()));
};


/**
 * optional bytes files = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFiles()`
 * @return {!Uint8Array}
 */
proto.note.FullNoteInfo.prototype.getFiles_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFiles()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.note.FullNoteInfo} returns this
 */
proto.note.FullNoteInfo.prototype.setFiles = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdOn = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.note.FullNoteInfo.prototype.getCreatedon = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.note.FullNoteInfo} returns this
*/
proto.note.FullNoteInfo.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.note.FullNoteInfo} returns this
 */
proto.note.FullNoteInfo.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.note.FullNoteInfo.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool favorite = 6;
 * @return {boolean}
 */
proto.note.FullNoteInfo.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.note.FullNoteInfo} returns this
 */
proto.note.FullNoteInfo.prototype.setFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


goog.object.extend(exports, proto.note);
