import { CommonActionTypes, LoadingState } from "../common/types";
import { ShortNote } from "../notes/types";

export const GET_DASHBOARD = "@@DASHBOARD/GET_DASHBOARD";
export const SET_DASHBOARD = "@@DASHBOARD/SET_DASHBOARD";
export const ADD_CARD = "@@DASHBOARD/ADD_CARD";
export const CARD_ADDED = "@@DASHBOARD/CARD_ADDED"
export const REMOVE_CARD = "@@DASHBOARD/REMOVE_CARD";
export const CARD_REMOVED = "@@DASHBOARD/CARD_REMOVED"
export const CARD_CHANGED = "@@DASHBOARD/CARD_CHANGED";

export interface DashboardState {
    readonly cards: DashboardCardData[];
    readonly loadingState: LoadingState;
}

export interface DashboardCardData {
    id: string;
    title: string;
    text: string;
    fileCount: number;
}

interface GetDashboardAction {
    type: typeof GET_DASHBOARD;
}

interface SetDashboardCardsAction {
    type: typeof SET_DASHBOARD;
    data: DashboardCardData[];
}

interface AddCardAction {
    type: typeof ADD_CARD;
    data: ShortNote;
}

interface CardAddedAction {
    type: typeof CARD_ADDED;
    data: DashboardCardData;
}

interface RemoveCardAction {
    type: typeof REMOVE_CARD;
    data: DashboardCardData;
}

interface CardRemovedAction {
    type: typeof CARD_REMOVED;
    data: DashboardCardData;
}

interface CardChangedAction {
    type: typeof CARD_CHANGED;
    data: DashboardCardData;
}

export type DashboardActionTypes =
    CommonActionTypes
    | GetDashboardAction
    | SetDashboardCardsAction
    | AddCardAction
    | CardAddedAction
    | RemoveCardAction
    | CardRemovedAction
    | CardChangedAction;