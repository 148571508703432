import { combineReducers } from "redux";

import { SessionReducer } from './sessions/reducers';
import { NotesReducer } from './notes/reducers';
import { CommonReducer } from './common/reducers';
import { DashboardReducer } from './dashboard/reducers';

import { SessionState } from "./sessions/types";
import { NotesState } from './notes/types';
import { CommonState } from './common/types';
import { DashboardState } from './dashboard/types';


export interface AppState {
    common: CommonState;
    session: SessionState;
    note: NotesState;
    dashboard: DashboardState;
}

export const createRootReducer = () => combineReducers({
    common: CommonReducer,
    session: SessionReducer,
    note: NotesReducer,
    dashboard: DashboardReducer
});

