import { Dispatch } from "redux";

import { NoteClient } from '../../grpc/generated/note_pb_service';
import { FileClient } from '../../grpc/generated/file_pb_service';
import { CreateNoteRequest, FullNoteInfo, GetNoteListRequest, NoteList, UpdateNoteRequest } from '../../grpc/generated/note_pb';
import * as t from "./types";
import { UserSession } from "../sessions/types";
import { GrpcClient } from "../../grpc/helpers";
import { FileContent } from "../../grpc/generated/file_pb";
import { encrypt, decrypt, randomKey, decryptFile, encryptFile, decryptFileList, encryptFileList } from "../../crypto";
import { AppThunk } from "../common/actions";
import { IdMessage } from "../../grpc/generated/common_pb";
import { CARD_CHANGED, CARD_REMOVED, DashboardActionTypes, DashboardCardData } from "../dashboard/types";

const noteGrpc = new GrpcClient(NoteClient);
const fileGrpc = new GrpcClient(FileClient);

export const getNotes: AppThunk = (session: UserSession) => {
    return async (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.GET_ALL_NOTES });

        const list = await noteGrpc.unaryCall<GetNoteListRequest, NoteList>(x => x.getList, new GetNoteListRequest(), session, dispatch);
            
        const notesList: t.NotesList = {
            notes: list.getNotesList().map(x => {
                return { 
                    id: x.getId(), 
                    title: decrypt(x.getTitle_asB64(), session.key), 
                    fileIds: decryptFileList(x.getFiles_asB64(), session.key),
                    createdOn: x.getCreatedon()!.toDate(),
                    favorite: x.getFavorite()
                }
            }),
            hasMore: list.getHasmore()
        }
        dispatch({ type: t.SET_ALL_NOTES, data: notesList });
    }
}

export const getNote: AppThunk = (id: string, session: UserSession) => {
    return async (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.GET_NOTE, data: id });

        const request = new IdMessage();
        request.setId(id);

        const note = await noteGrpc.unaryCall<IdMessage, FullNoteInfo>(x => x.get, request, session, dispatch);

        const currentNote: t.FullNote = {
            id: note.getId(),
            title: decrypt(note.getTitle_asB64(), session.key),
            text: decrypt(note.getText_asB64(), session.key),
            files: [],
            filesToDeleteOnUpdate: [],
            createdOn: note.getCreatedon()!.toDate(),
            favorite: note.getFavorite()
        };

        const fileIDs = decryptFileList(note.getFiles_asB64(), session.key);
        const files = await getFiles(fileIDs, session, dispatch);
        
        currentNote.files.push(...files);
        dispatch({ type: t.SET_CURRENT_NOTE, data: currentNote });
    }
}

export const setCurrentNote: AppThunk = (note: t.FullNote) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.SET_CURRENT_NOTE, data: note });
    }
}

export const resetCurrentNote : AppThunk = () => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.RESET_CURRENT_NOTE });
    }
}

export const revertPreviousNoteVersion: AppThunk = (note: t.FullNote) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.CANCEL_NOTE_EDIT, data: note });
    }
}

export const createNote: AppThunk = (note: t.FullNote, session: UserSession) => {
    return async (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.CREATE_NOTE, data: note });

        const files = await createFiles(note.files, session, dispatch);

        const request = new CreateNoteRequest();
        request.setTitle(encrypt(note.title, session.key));
        request.setText(encrypt(note.text, session.key));
        request.setFiles(encryptFileList(files.map(x => x.id), session.key));
        request.setFavorite(note.favorite);

        const id = await noteGrpc.unaryCall<CreateNoteRequest, IdMessage>(x => x.create, request, session, dispatch);

        note.id = id.getId();
        dispatch({ type: t.NOTE_CREATED, data: note });
    }
}

export const editCurrentNote: AppThunk = (note: t.FullNote) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.EDIT_NOTE, data: note });
    }
}

export const updateNote: AppThunk = (note: t.FullNote, session: UserSession) => {
    return async (dispatch: Dispatch<t.NoteActionTypes | DashboardActionTypes>) => {
        dispatch({ type: t.UPDATE_NOTE, data: note });

        await createFiles(note.files.filter(x => !x.id), session, dispatch);
        await removeFiles(note.filesToDeleteOnUpdate.filter(x => x.id).map(x => x.id!), session, dispatch);

        const request = new UpdateNoteRequest();
        request.setId(note.id);
        request.setTitle(encrypt(note.title, session.key));
        request.setText(encrypt(note.text, session.key));
        request.setFavorite(note.favorite);

        request.setFiles(encryptFileList(note.files.map(x => x.id), session.key));

        await noteGrpc.unaryCall(x => x.update, request, session, dispatch);

        // note update
        dispatch({ type: t.NOTE_UPDATED, data: note });

        // card update
        const card: DashboardCardData = { id: note.id, title: note.title, text: note.text, fileCount: note.files.length };
        dispatch({ type: CARD_CHANGED, data: card });
    }
}

export const showDeleteNoteDialog: AppThunk = (note: t.ShortNote | t.FullNote) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.SET_DELETING_NOTE, data: note });
    }
}

export const hideDeleteNoteDialog: AppThunk = () => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.SET_DELETING_NOTE, data: undefined });
    }
}

export const deleteNote: AppThunk = (note: t.ShortNote | t.FullNote, session: UserSession) => {
    return async (dispatch: Dispatch<t.NoteActionTypes | DashboardActionTypes>) => {
        const files = "files" in note ? note.files.map(x => x.id!) : note.fileIds

        await removeFiles(files, session, dispatch);

        const request = new IdMessage();
        request.setId(note.id);

        await noteGrpc.unaryCall(x => x.delete, request, session, dispatch);

        dispatch({ type: t.NOTE_DELETED, data: note });

        // remove dashboard card in case it added
        const card: DashboardCardData = { id: note.id, title: '', text: '', fileCount: 0 };
        dispatch({ type: CARD_REMOVED, data: card });
    }
}

export const addNoteFile: AppThunk = (note: t.FullNote, file: t.FileData) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.ADD_NOTE_IMAGE, file: file, note: note });
    }
}

export const removeNoteFile: AppThunk = (note: t.FullNote, file: t.FileData) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.REMOVE_NOTE_IMAGE, file: file, note: note });
    }
}

export const downloadFile: AppThunk = (id: string, session: UserSession, onSuccess: (file: t.FileData) => void) => {
    return async (dispatch: Dispatch<t.NoteActionTypes>) => {
        const files = await getFiles([id], session, dispatch);
        onSuccess(files[0]);
    }
}

export const showQRCode : AppThunk = (title: string, value: string) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        const qrcode: t.QRCodeData = { title: title, valueToCode: value };
        dispatch({ type: t.SHOW_QR_CODE, data: qrcode });
    }
}

export const hideQRCode : AppThunk = () => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.HIDE_QR_CODE });
    }
}

export const sortNotes : AppThunk = (sort: t.SortState) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.SORT_NOTES, data: sort });
    }
}

export const changeNotesFilter : AppThunk = (filter: t.NotesFilter) => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        dispatch({ type: t.SET_NOTE_LIST_FILTER, data: filter });
    }
}

export const resetNotesFilter : AppThunk = () => {
    return (dispatch: Dispatch<t.NoteActionTypes>) => {
        const filter: t.NotesFilter = { includeFiles: false, includeFavorites: false, tags: new Set<string>(), title: "", showDialog: false };
        dispatch({ type: t.SET_NOTE_LIST_FILTER, data: filter });
    }
}

function getFiles(ids: string[], session: UserSession, dispatch: Dispatch<t.NoteActionTypes>) {
    const filePromises = ids.map(async fileId => {
        const fileRequest = new IdMessage();
        fileRequest.setId(fileId);

        const file = await fileGrpc.unaryCall<IdMessage, FileContent>(x => x.get, fileRequest, session, dispatch);
        const fileData: t.FileData = { 
            id: file.getId(), 
            name: decrypt(file.getName_asB64(), session.key),
            size: file.getSize(),
            content: decryptFile(file.getContent_asU8(), session.key, file.getSize()),
            type: file.getType(),
            key: randomKey()
        };
        return fileData;
    });

    return Promise.all(filePromises);
}

function createFiles(files: t.FileData[], session: UserSession, dispatch: Dispatch<t.NoteActionTypes>) {
    const promises = files.map(async file => {
        const fileRequest = new FileContent();
        fileRequest.setName(encrypt(file.name, session.key));
        fileRequest.setSize(file.size);
        fileRequest.setContent(encryptFile(file.content!, session.key));
        fileRequest.setType(file.type);

        const id = await fileGrpc.unaryCall<FileContent, IdMessage>(x => x.create, fileRequest, session, dispatch);
        file.id = id.getId();

        return file;
    })

    return Promise.all(promises);
}

function removeFiles(ids: string[], session: UserSession, dispatch: Dispatch<t.NoteActionTypes>) {
    const promises = ids.map(id => {
        const request = new IdMessage();
        request.setId(id);

        return fileGrpc.unaryCall(x => x.delete, request, session, dispatch);
    });

    return Promise.all(promises);
}