/* eslint-disable */
// package: file
// file: file.proto

var file_pb = require("./file_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var File = (function () {
  function File() {}
  File.serviceName = "file.File";
  return File;
}());

File.Get = {
  methodName: "Get",
  service: File,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdMessage,
  responseType: file_pb.FileContent
};

File.Create = {
  methodName: "Create",
  service: File,
  requestStream: false,
  responseStream: false,
  requestType: file_pb.FileContent,
  responseType: common_pb.IdMessage
};

File.Delete = {
  methodName: "Delete",
  service: File,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdMessage,
  responseType: google_protobuf_empty_pb.Empty
};

exports.File = File;

function FileClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FileClient.prototype.get = function get(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(File.Get, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FileClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(File.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FileClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(File.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FileClient = FileClient;

