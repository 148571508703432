import { Reducer } from "redux";

import { nameof } from "../../common";
import * as ct from "../common/types";
import * as t from "./types";
import { splitTags } from "../../components/common/Tag";

const initFullNote = { id: "", title: "", text: "", files: [], filesToDeleteOnUpdate: [], createdOn: new Date(), favorite: false };
const initState: t.NotesState = {
    allNotes: [],
    filteredNotes: [],
    filter: { tags: new Set<string>(), includeFiles: false, includeFavorites: false, title: "", showDialog: false },
    notesLoadingState: "INIT",
    notesSort: { property: nameof<t.ShortNote>("createdOn"), direction: "desc" },
    hasMore: false,
    current: { ...initFullNote },
    currentPrevVersion: { ...initFullNote },
    currentLoadingState: "INIT"
};

const reducer: Reducer<t.NotesState> = (state = initState, action: t.NoteActionTypes) => {
    let current: t.FullNote;
    let all: t.ShortNote[];

    switch (action.type) {
        case t.GET_ALL_NOTES:
            return { ...state, notesLoadingState: "LOADING" };

        case t.SET_ALL_NOTES:
            return {
                ...state,
                notesLoadingState: "LOADED",
                allNotes: action.data.notes,
                filteredNotes: action.data.notes,
                hasMore: action.data.hasMore,
                filter: initState.filter
            };

        case t.GET_NOTE:
        case t.CREATE_NOTE:
        case t.UPDATE_NOTE:
            return { ...state, currentLoadingState: "LOADING" };

        case t.SET_CURRENT_NOTE:
            return { ...state, currentLoadingState: "LOADED", current: action.data, currentPrevVersion: { ...action.data } };

        case t.RESET_CURRENT_NOTE:
            return { ...state, currentLoadingState: "INIT", current: initState.current, currentPrevVersion: initState.currentPrevVersion };

        case t.NOTE_CREATED:
            const newNode = mapToShort(action.data);
            all = [...state.allNotes, newNode];
            return { 
                ...state, 
                currentLoadingState: "LOADED", 
                current: action.data, 
                currentPrevVersion: action.data, 
                allNotes: all,
                filteredNotes: filterNotes(all, state.filter)
            };

        case t.EDIT_NOTE:
            return { ...state, current: action.data };

        case t.NOTE_UPDATED:
            current = { ...action.data, filesToDeleteOnUpdate: [] };
            const currentShort = mapToShort(current);
            return {
                ...state,
                currentLoadingState: "LOADED",
                current: current,
                currentPrevVersion: { ...current },
                allNotes: state.allNotes.map(x => x.id !== currentShort.id ? x : currentShort),
                filteredNotes: state.filteredNotes.map(x => x.id !== currentShort.id ? x : currentShort)
            };

        case t.CANCEL_NOTE_EDIT:
            return { ...state, current: { ...state.currentPrevVersion } };

        case t.NOTE_DELETED:
            all = state.allNotes.filter(x => x.id !== action.data.id);
            return { 
                ...state, 
                allNotes: all, 
                filteredNotes: filterNotes(all, state.filter),
                noteToDelete: undefined 
            };

        case t.SET_DELETING_NOTE:
            return { ...state, noteToDelete: action.data };

        case t.ADD_NOTE_IMAGE:
            current = { ...state.current, files: [...state.current.files, action.file] };
            return { ...state, current: current };

        case t.SHOW_QR_CODE:
            return { ...state, qrcode: action.data };

        case t.HIDE_QR_CODE:
            return { ...state, qrcode: undefined };

        case t.REMOVE_NOTE_IMAGE:
            current = {
                ...state.current,
                files: state.current.files.filter(x => x.key !== action.file.key),
                filesToDeleteOnUpdate: [...state.current.filesToDeleteOnUpdate, action.file]
            };
            return { ...state, current: current };

        case t.SORT_NOTES:
            return { ...state, notesSort: action.data };

        case t.SET_NOTE_LIST_FILTER:
            return { ...state, filter: action.data, filteredNotes: filterNotes(state.allNotes, action.data) };

        case ct.SIGN_OUT_COMPLETE:
        case ct.PURGE_STATE:
            return initState;

        case ct.COMMON_ERROR:
            if (state.notesLoadingState === "LOADING") {
                return { ...state, notesLoadingState: "ERROR" };
            }

            if (state.currentLoadingState === "LOADING") {
                return { ...state, currentLoadingState: "ERROR" };
            }

            return state;

        default:
            return state;
    }
}

export { reducer as NotesReducer };

function mapToShort(note: t.FullNote): t.ShortNote {
    return {
        id: note.id,
        title: note.title,
        createdOn: note.createdOn,
        fileIds: note.files.filter(x => x.id).map(x => x.id!),
        favorite: note.favorite
    };
}

// for testing purpose
export function filterNotes(notes: t.ShortNote[], filter: t.NotesFilter): t.ShortNote[] {
    let filtered = notes;
    if (filter.tags.size > 0) {
        filtered = filtered.filter(x => {
            const noteTags = splitTags(x.title).tags;
            return noteTags.some(tag => filter.tags.has(tag));
        })
    }

    if (filter.includeFiles) {
        filtered = filtered.filter(x => x.fileIds.length > 0);
    }

    if (filter.title) {
        const textFilter = filter.title.toLowerCase();
        filtered = filtered.filter(x => {
            const noteTitle = splitTags(x.title).text;
            return noteTitle.toLowerCase().indexOf(textFilter) >= 0;
        }) 
    }

    if (filter.includeFavorites) {
        filtered = filtered.filter(x => x.favorite);
    }

    return filtered;
}