import React, { useEffect } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import moment from "moment";
import { Card, CardBody, CardFooter, CardHeader, Layer, Heading, Text } from "grommet";
import { Refresh, Close } from "grommet-icons";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { AppState } from "../../store";
import { showHideSignOutNotificationDialog, prolongSession, sessionFinished } from "../../store/sessions/actions";
import IconLabelButton from "../common/IconLabelButton";
import SessionCountdown from "../common/SessionCountdown";
import { UserSession } from "../../store/sessions/types";

const mapState = ({ session }: AppState) => ({ 
    session: session.current,
    showDialog: session.showSessionEndDialog
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        prolongate: (session: UserSession) => {
            dispatch(prolongSession(session));
        },
        closeDialog: () => {
            dispatch(showHideSignOutNotificationDialog(false));
        }
    }
}

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>

const AutoSignOutDialog = (props: AllProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!props.session.authenticated) {
            return;
        }
        
        const expiredOnDate = moment(props.session.expiresOn);
        const notificationDate = moment(expiredOnDate);
        notificationDate.subtract(1, "minute"); // todo: to config

        const signOutTimer = setTimeout(() => dispatch(sessionFinished()), expiredOnDate.diff(moment())); // todo: !!! bug prolong session does not work here

        let notificationTimer: NodeJS.Timeout;
        const dialogTimeout = notificationDate.diff(moment());
        if (dialogTimeout > 0) {
            notificationTimer = setTimeout(() => dispatch(showHideSignOutNotificationDialog(true)), dialogTimeout);
        }
        return () => { 
            console.log("cancel sign out");
            clearTimeout(signOutTimer);
            if (notificationTimer) {
                clearTimeout(notificationTimer);
            }
        }
    })

    if (props.session.expiresOn < new Date()) {
        return <React.Fragment />
    }

    if (props.showDialog) {
        return (
            <Layer>
                <Card width="large">
                    <CardHeader pad="medium" alignSelf="center">
                        <Heading level="3" margin="none" textAlign="center">Our session is about to end</Heading>
                    </CardHeader>
                    <CardBody pad="medium" align="center">
                        <Text margin={{bottom: "small"}} size="large">Session will end in</Text>
                        <SessionCountdown />
                    </CardBody> 
                    <CardFooter pad="medium" background="light-5">
                        <IconLabelButton icon={Refresh} label="Prolong session" primary onClick={() => props.prolongate(props.session) } />
                        <IconLabelButton icon={Close} label="Close" onClick={props.closeDialog} />
                    </CardFooter>
                </Card>
            </Layer>
        )
    }

    return <React.Fragment />
}

export default connector(AutoSignOutDialog);