import React from 'react';
import { Button, Box, Text } from 'grommet';
import { useHistory } from 'react-router-dom';

import { getOnClick, IconButtonPropsBase } from './IconButtonBase';

interface IconOnlyButtonProps extends IconButtonPropsBase {
    label: string;
}

const IconOnlyButton = (props: IconOnlyButtonProps) => {
    const history = useHistory();

    const onClick = getOnClick(history, props);

    const bgColor = props.primary ? "brand" : props.bgColor || "light-1";
    const iconColor = props.primary ? "white" : props.color;
    return (
        <Box 
            align="center" 
            background={bgColor} 
            round="small" 
            fill={props.fill} 
        >
            <Button hoverIndicator="light-6" onClick={onClick} type={props.type} fill={props.fill} disabled={props.disabled}>
                <Box pad={{ horizontal: "small", vertical: "xsmall" }} direction="row" align="center" gap="small" >
                    <props.icon color={iconColor} size={props.size} />
                    <Text color={props.color} size={props.size}>{props.label}</Text>
                </Box>
            </Button>
        </Box>
    )
}

export default IconOnlyButton;