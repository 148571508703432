import { Dispatch } from "redux";
import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { AppState } from "..";
import { UserSession } from "../sessions/types";
import { BUTTON_OVER, CLOSE_ERROR, CommonActionTypes, COMMON_ERROR, ErrorData, GENERIC_ERROR_CODE, PURGE_STATE, SET_MENU_OPENED, SET_VIEW_SIZE, SIGN_OUT_COMPLETE } from "./types";
import { GrpcClient } from "../../grpc/helpers";
import { SessionClient } from '../../grpc/generated/session_pb_service';
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

export type AppThunk = ActionCreator<ThunkAction<void, AppState, null, Action<string>>>;

const sessionClient = new GrpcClient(SessionClient);

export const signOutUser : AppThunk = (session: UserSession) => {
    return async (dispatch: Dispatch<CommonActionTypes>) => {
        await sessionClient.unaryCall(x => x.stop, new Empty(), session, dispatch);
        dispatch({ type: SIGN_OUT_COMPLETE });
    }
}

export const showErrorMessage : AppThunk = (message: string, code = GENERIC_ERROR_CODE) => {
    return (dispatch: Dispatch<CommonActionTypes>) => {
        dispatch({ type: COMMON_ERROR, error: { code: code, message: message } });
    }
}

export const closeErrorMessage : AppThunk = (error: ErrorData) => {
    return (dispatch: Dispatch<CommonActionTypes>) => {
        dispatch({ type: CLOSE_ERROR, error: error });
    }
}

export const purgeLoadedState : AppThunk = () => {
    return (dispatch: Dispatch<CommonActionTypes>) => {
        dispatch({ type: PURGE_STATE });
    }
}

export const changeButtonOver : AppThunk = (id: string, over: boolean) => {
    return (dispatch: Dispatch<CommonActionTypes>) => { 
        dispatch({ type: BUTTON_OVER, id: id, over: over });
    }
}

export const setViewSize = (size: string): CommonActionTypes => {
    return { type: SET_VIEW_SIZE, size: size };
}

export const setMenuOpened = (opened: boolean): CommonActionTypes => {
    return { type: SET_MENU_OPENED, opened: opened };
}
