import { History } from "history";
import { Store, createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { logger } from "redux-logger";
import thunk from "redux-thunk";

import { createRootReducer, AppState } from "./store";
import { createRedirectMiddleware } from "./store/redirectMiddleware";

export default function configureStore(history: History, initialState = {}): Store<AppState> {
    const middlewares = [
        createRedirectMiddleware(history),
        thunk
    ];

    const enhancer =
        process.env.NODE_ENV === "development"
            ? composeWithDevTools(applyMiddleware(...middlewares, logger))
            : compose(applyMiddleware(...middlewares));

    const store = createStore(
        createRootReducer(),
        initialState,
        enhancer
    );

    console.log(store.getState());
    return store;
}