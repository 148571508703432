import React from "react";
import { Stack, Box, Text } from "grommet";
import { Attachment } from "grommet-icons";

import { ShortNote } from "../../store/notes/types";

interface NotesImageCountProps {
    note: ShortNote;
}

const NotesImageCount = (props: NotesImageCountProps) => {
    if (props.note.fileIds.length === 0) {
        return <React.Fragment />
    }

    return (
        <Stack anchor="bottom-right">
            <Attachment size="32px" />
            <Box background="brand" pad={{ horizontal: "xsmall" }} round>
                <Text size="small">{props.note.fileIds.length}</Text>
            </Box>
        </Stack>
    )
}

export default NotesImageCount;