import { UserSession } from "../store/sessions/types";

const SESSION_ID_KEY = "cv:s";
const CRYPTO_PASS_KEY = "cv:p";

export function inDevMode() {
    return process?.env?.NODE_ENV === "development";
}

export function getSessionId(): string {
    return inDevMode() ? localStorage.getItem(SESSION_ID_KEY) || '' : '';
} 

export function getCryptoPassword(): string {
    return inDevMode() ? localStorage.getItem(CRYPTO_PASS_KEY) || '' : '';
}

export function saveSessionInDevMode(session: UserSession) {
    if (!inDevMode()) {
        return;
    }

    if (session.authenticated) {
        localStorage.setItem(SESSION_ID_KEY, session.id);
        localStorage.setItem(CRYPTO_PASS_KEY, session.key);
    } else {
        localStorage.removeItem(SESSION_ID_KEY);
        localStorage.removeItem(CRYPTO_PASS_KEY);
    }
}

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const removeHtmlTags = (html: string): string => {
    const tmpElement = document.createElement("div");
    tmpElement.innerHTML = html;
    return tmpElement.textContent || tmpElement.innerText || "";
}