import React, { useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Box, Text, Main, Heading, DataTable, ColumnConfig } from "grommet";
import { View, Trash, Edit, AddCircle, Clear, Star } from "grommet-icons";
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState } from '../../store';
import { getNotes, resetNotesFilter, changeNotesFilter } from '../../store/notes/actions';
import IconOnlyButton from '../common/IconOnlyButton';
import IconLabelButton from '../common/IconLabelButton';
import DeleteNoteDialog from './DeleteNoteDialog';
import NotesImageCount from './NotesImageCount';
import Loading from '../common/Loading';
import { showDeleteNoteDialog, sortNotes, resetCurrentNote } from '../../store/notes/actions';
import '../../dateExtensions';
import { NotesFilter, ShortNote, SortState } from '../../store/notes/types';
import { nameof } from "../../common";
import Tag, { splitTags } from "../common/Tag";
import NoteListFilter from "./NoteListFilter";

const mapState = ({ note, session }: AppState) => ({
    list: note.filteredNotes, 
    totalNotesCount: note.allNotes.length,
    listLoadingState: note.notesLoadingState,
    sort: note.notesSort,
    listFilter: note.filter,
    current: note.current,
    currentLoadingState: note.currentLoadingState,
    session: session.current
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => { 
    return {
        showDeleteDialog: (note: any) => {
            dispatch(showDeleteNoteDialog(note));
        },
        changeSort: (sort: SortState) => {
            dispatch(sortNotes(sort));
        },
        resetFilters: () => {
            dispatch(resetNotesFilter());
        }
    }
};

const connector = connect(mapState, mapDispatch);

type AllProps = ConnectedProps<typeof connector>

const NotesList = (props: AllProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (props.currentLoadingState !== "INIT") {
            dispatch(resetCurrentNote());
        }
    
        if (props.listLoadingState === "INIT") {
            dispatch(getNotes(props.session)); // todo: reload on page navigate
        }
    })

    if (props.listLoadingState === "LOADING" || props.listLoadingState === "INIT") {
        return <Loading />
    }

    if (props.totalNotesCount === 0) {
        return (
            <Box align="center" justify="center" flex>
                <Main pad="large">
                    <Heading>There is no any note yet ...</Heading>
                    <IconLabelButton icon={AddCircle} label="Create new note" href="/note/create" primary />
                </Main>
            </Box>
        )
    }

    if (props.list.length === 0) {
        return (
            <Box direction="column" justify="start" pad="medium" fill overflow="auto">
                <NoteListFilter />
                <Main pad="large">
                    <Heading level="2">There is no any note that meets your criteria ...</Heading>
                    <IconLabelButton icon={Clear} label="Reset filters" onClick={() => props.resetFilters()} />
                </Main>
            </Box>
        )
    }

    const makeHeader = (text: string) => <Text weight="bold">{text}</Text>;

    const onTagClick = (tag: string) => {
        if (props.listFilter.tags.has(tag)) {
            return;
        }

        const newFilter: NotesFilter = { ...props.listFilter, tags: props.listFilter.tags.add(tag) };
        dispatch(changeNotesFilter(newFilter));
    };

    const columns: ColumnConfig<ShortNote>[] = [
        { property: nameof<ShortNote>("favorite"), header: makeHeader("Fav"), align: "center", size: "xxsmall", render: x => {
            return x.favorite ? <Star color="accent-4" /> : <React.Fragment />
        }},
        { property: nameof<ShortNote>("fileIds"), header: makeHeader("Files"), align: "end", size: "xxsmall", render: x => <NotesImageCount note={x} /> },
        { property: nameof<ShortNote>("title"), header: makeHeader("Title"), render: x => {
            const title = splitTags(x.title);
            return (
                <Box direction="row" gap="xsmall">
                    <Text>{title.text}</Text>
                    {title.tags.map((tag, i) => <Tag key={i} tag={tag} onClick={onTagClick} />)}
                </Box>
            )
        }},
        { property: nameof<ShortNote>("createdOn"), header: makeHeader("Created On"), align: "end", render: x => (
            <Text title={x.createdOn.display()}>{x.createdOn.ago()}</Text>
        )},
        { property: "actions", header: makeHeader("Actions"), sortable: false, render: x => (
            <Box alignContent="end" direction="row" gap="small">
                <IconOnlyButton icon={View} href={`/note/${x.id}/view`} title="View" titleAlign="bottom" idKey={"btn-view-" + x.id} />
                <IconOnlyButton icon={Edit} href={`/note/${x.id}/edit`} title="Edit" titleAlign="bottom" idKey={"btn-edit-" + x.id} />
                <IconOnlyButton icon={Trash} color="red" onClick={() => props.showDeleteDialog(x)} title="Remove" titleAlign="bottom" idKey={"btn-remove-" + x.id}  /> 
            </Box>
        )}
    ]

    return (
        <React.Fragment>
            <Box direction="column" justify="start" pad="medium" fill overflow="auto">
                <NoteListFilter />
                <Box>
                    <DataTable 
                        margin={{horizontal: "medium"}}
                        pad={{horizontal: "medium", vertical: "xsmall"}}
                        columns={columns} 
                        data={props.list} 
                        sort={props.sort}
                        primaryKey={nameof<ShortNote>("id")} 
                        onClickRow={x => history.push(`/note/${x.datum.id}/view`)}
                        onSort={props.changeSort}
                    />
                </Box>
            </Box>
            <DeleteNoteDialog />
        </React.Fragment>
    )
}

export default connector(NotesList);