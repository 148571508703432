import { CommonActionTypes, LoadingState } from "../common/types";

export const GET_ALL_NOTES = "@@NOTES/GET_ALL_NOTES";
export const SET_ALL_NOTES = "@@NOTES/SET_ALL_NOTES";
export const SORT_NOTES = "@@NOTES/SORT_NOTES";
export const SET_NOTE_LIST_FILTER = "@@NOTES/SET_NOTE_LIST_FILTER";

export const GET_NOTE = "@@NOTES/GET_NOTE";
export const SET_CURRENT_NOTE = "@@NOTES/SET_CURRENT_NOTE";
export const RESET_CURRENT_NOTE = "@@NOTES/RESET_CURRENT_NOTE";

export const CREATE_NOTE = "@@NOTES/CREATE_NOTE";
export const NOTE_CREATED = "@@NOTES/NOTE_CREATED";

export const UPDATE_NOTE = "@@NOTES/UPDATE_NOTE";
export const NOTE_UPDATED = "@@NOTES/NOTE_UPDATED";
export const EDIT_NOTE = "@@NOTES/EDIT_NOTE";
export const CANCEL_NOTE_EDIT = "@@NOTES/CANCEL_NOTE_EDIT"

export const SET_DELETING_NOTE = "@@NOTES/SET_DELETING_NOTE";
export const NOTE_DELETED = "@@NOTES/NOTE_DELETED";

export const ADD_NOTE_IMAGE = "@@NOTES/ADD_NOTE_IMAGE";
export const REMOVE_NOTE_IMAGE = "@@NOTES/REMOVE_NOTE_IMAGE";

export const SHOW_QR_CODE = "@@NOTES/SHOW_QR_CODE";
export const HIDE_QR_CODE = "@@NOTES/HIDE_QR_CODE"

export interface NotesState {
    readonly allNotes: ShortNote[];
    readonly filteredNotes: ShortNote[];
    readonly filter: NotesFilter;
    readonly notesLoadingState: LoadingState;
    readonly notesSort: SortState;
    readonly hasMore: boolean; // todo: not used
    readonly current: FullNote;
    readonly currentPrevVersion: FullNote;
    readonly currentLoadingState: LoadingState;
    readonly noteToDelete?: ShortNote | FullNote;
    readonly qrcode?: QRCodeData;
}

export interface NotesFilter {
    tags: Set<string>;
    includeFiles: boolean;
    includeFavorites: boolean;
    title: string;
    showDialog: boolean;
}

export interface ShortNote {
    id: string;
    title: string;
    fileIds: string[];
    createdOn: Date;
    favorite: boolean;
}

export interface FullNote {
    id: string;
    title: string;
    text: string;
    files: FileData[];
    filesToDeleteOnUpdate: FileData[];
    createdOn: Date;
    favorite: boolean;
}

export interface NotesList {
    notes: ShortNote[];
    hasMore: boolean;
}

export interface FileData {
    id?: string;
    name: string;
    size: number;
    content?: string;
    type: FileDataType;
    key: string;
}

export enum FileDataType {
    Image = 0,
    Other = 100
} 

export interface QRCodeData {
    title: string;
    valueToCode: string;
}

export interface SortState {
    property: string; 
    direction: 'asc' | 'desc';
}

interface GetNotesAction {
    type: typeof GET_ALL_NOTES;
}

interface SetNotesAction {
    type: typeof SET_ALL_NOTES;
    data: NotesList;
}

interface SetCurrentNoteAction {
    type: typeof SET_CURRENT_NOTE;
    data: FullNote;
}

interface ResetCurrentNoteAction {
    type: typeof RESET_CURRENT_NOTE;
}

interface CreateNoteAction {
    type: typeof CREATE_NOTE;
    data: FullNote;
}

interface NoteCreatedAction {
    type: typeof NOTE_CREATED;
    data: FullNote;
}

interface GetNoteAction {
    type: typeof GET_NOTE;
    data: string;
}

interface UpdateNoteAction {
    type: typeof UPDATE_NOTE;
    data: FullNote;
}

interface NoteUpdatedAction {
    type: typeof NOTE_UPDATED;
    data: FullNote;
}

interface SetDeletingNoteAction {
    type: typeof SET_DELETING_NOTE;
    data?: ShortNote | FullNote;
}

interface NoteDeletedAction {
    type: typeof NOTE_DELETED;
    data: ShortNote | FullNote;
}

interface AddNoteImageAction {
    type: typeof ADD_NOTE_IMAGE;
    file: FileData; 
    note: FullNote;
}

interface RemoveNoteImageAction {
    type: typeof REMOVE_NOTE_IMAGE;
    file: FileData; 
    note: FullNote;
}

interface ShowQRCodeAction {
    type: typeof SHOW_QR_CODE;
    data: QRCodeData;
}

interface HideQRCodeAction {
    type: typeof HIDE_QR_CODE;
}

interface SortNotesAction {
    type: typeof SORT_NOTES;
    data: SortState;
}

interface EditNoteAction {
    type: typeof EDIT_NOTE;
    data: FullNote;
}

interface CancelNoteUpdate {
    type: typeof CANCEL_NOTE_EDIT;
    data: FullNote;
}

interface SetNoteListFilterAction {
    type: typeof SET_NOTE_LIST_FILTER;
    data: NotesFilter;
}

export type NoteActionTypes = 
    CommonActionTypes
    | GetNotesAction
    | SetNotesAction
    | SetCurrentNoteAction
    | ResetCurrentNoteAction
    | CreateNoteAction
    | NoteCreatedAction
    | GetNoteAction
    | UpdateNoteAction
    | NoteUpdatedAction
    | SetDeletingNoteAction
    | NoteDeletedAction
    | AddNoteImageAction
    | RemoveNoteImageAction
    | ShowQRCodeAction
    | HideQRCodeAction
    | SortNotesAction
    | EditNoteAction
    | CancelNoteUpdate
    | SetNoteListFilterAction;