import React, { useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Checkmark, Close } from 'grommet-icons';

import { AppState } from '../../store';
import { getNote, updateNote } from '../../store/notes/actions';
import NoteForm from './NoteForm';
import Loading from '../common/Loading';

const mapState = ({ note, session }: AppState) => ({
    note: note.current,
    loadingState: note.currentLoadingState,
    session: session.current
});

const connector = connect(mapState);
type AllProps = ConnectedProps<typeof connector>

interface RouteParams {
    id: string;
}

const EditNote = (props: AllProps & RouteComponentProps<RouteParams>) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const noteId = props.match.params.id;
        if (props.loadingState === "INIT" || props.note.id !== noteId) {     
            dispatch(getNote(noteId, props.session));
        }
    })

    if (props.loadingState === "LOADING" || props.loadingState === "INIT") {
        return <Loading />
    }
    
    return (
        <NoteForm 
            confirmIcon={Checkmark} 
            cancelIcon={Close} 
            confirmText="Update" 
            onSubmit={updateNote}
        />
    )
}

export default connector(EditNote);