import React from "react";
import { Box, Text, Heading, CheckBox, TextInput, Layer, FormField, List, Select } from "grommet";
import { Search, Close, Filter } from "grommet-icons";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AppState } from "../../store";
import Tag, { splitTags } from "../common/Tag";
import { NotesFilter } from "../../store/notes/types";
import { changeNotesFilter, resetNotesFilter } from "../../store/notes/actions";
import IconOnlyButton from "../common/IconOnlyButton";
import IconLabelButton from "../common/IconLabelButton"

const mapState = ({ note }: AppState) => ({
    filter: note.filter,
    allCount: note.allNotes.length,
    filteredCount: note.filteredNotes.length,
    allNotes: note.allNotes
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        addTag: (filter: NotesFilter, tag: string) => {
            const newFilter: NotesFilter = { ...filter, tags: filter.tags.add(tag) };
            dispatch(changeNotesFilter(newFilter));
        },
        removeTag: (filter: NotesFilter, tag: string) => {
            const tags = Array.from(filter.tags).filter(x => x !== tag);
            const newFilter: NotesFilter = { ...filter, tags: new Set<string>(tags) };

            dispatch(changeNotesFilter(newFilter));
        },
        toggleFileFilter: (filter: NotesFilter) => {
            const newFilter: NotesFilter = { ...filter, includeFiles: !filter.includeFiles };
            dispatch(changeNotesFilter(newFilter));
        },
        toggleFavoriteFilter: (filter: NotesFilter) => {
            const newFilter: NotesFilter = { ...filter, includeFavorites: !filter.includeFavorites };
            dispatch(changeNotesFilter(newFilter));
        },
        titleFilterChanged: (filter: NotesFilter, title: string) => {
            const newFilter: NotesFilter = { ...filter, title: title };
            dispatch(changeNotesFilter(newFilter));
        },
        toggleDialog: (filter: NotesFilter, show: boolean) => {
            const newFilter: NotesFilter = { ...filter, showDialog: show };
            dispatch(changeNotesFilter(newFilter));
        },
        resetFilter: () => {
            dispatch(resetNotesFilter());
        }
    }
}

const connector = connect(mapState, mapDispatch);
type AllProps = ConnectedProps<typeof connector>

const NoteListFilter = (props: AllProps) => {
    const filterTags = Array.from(props.filter.tags);
    const filterEnabled = props.filter.includeFiles || props.filter.includeFavorites || props.filter.tags.size || props.filter.title;
    const allTags = props.allNotes
        .flatMap(x => splitTags(x.title).tags)
        .filter(x => !props.filter.tags.has(x))
        .filter((tag, index, tags) => tags.indexOf(tag) === index)
        .sort()
        .map(x => ("#" + x));

    const dialog = (
        <Layer 
            position="right" 
            full="vertical" 
            onEsc={() => props.toggleDialog(props.filter, false)} 
            onClickOutside={() => props.toggleDialog(props.filter, false)}
        >
            <Box as="form" fill="vertical" overflow="auto" width="medium" pad="medium" gap="small">
                <Box flex={false} direction="row" justify="between">
                    <Heading level="3">Filter</Heading>
                    <IconOnlyButton icon={Close} onClick={() => props.toggleDialog(props.filter, false)} />
                </Box>
                <Box flex="grow" overflow="auto">
                    <FormField label="Title">
                        <TextInput
                            icon={<Search />}
                            placeholder="Filter by title ..."
                            value={props.filter.title}
                            onChange={x => props.titleFilterChanged(props.filter, x.target.value)}
                        />
                    </FormField>

                    <FormField label="Tags">
                        <Select 
                            options={allTags} 
                            placeholder="Choose tags to filter ..." 
                            onChange={x => props.addTag(props.filter, x.value.substring(1))} 
                        />
                        {filterTags.length === 0 && <Text weight="bold" color="status-unknown" margin="small">no filter on tags</Text>}
                        {filterTags.length !== 0 && 
                        <List
                            data={filterTags}
                            primaryKey={x => <Tag tag={x} onClick={tag => props.removeTag(props.filter, tag)} />}
                        />}
                    </FormField>

                    <FormField label="Files">
                        <CheckBox checked={props.filter.includeFiles} label="With files?" onChange={() => props.toggleFileFilter(props.filter)} />
                    </FormField>

                    <FormField label="Favorite">
                        <CheckBox checked={props.filter.includeFavorites} label="Only favorites" onChange={() => props.toggleFavoriteFilter(props.filter)} />
                    </FormField>
                </Box>
            </Box>
        </Layer>
    )

    const filterStatus = filterEnabled
        ? <Text>Shown {props.filteredCount} notes of {props.allCount}</Text> 
        : <Text>Total notes count: {props.allCount}</Text>

    const filterButtonColor = filterEnabled ? "status-warning" : "status-unknown"
    return (
        <Box direction="row" margin={{ horizontal: "large", bottom: "small" }} gap="small" justify="between" flex={false}>
            {filterStatus}
            <Box direction="row" gap="small">
                <IconLabelButton icon={Filter} label="Filters" bgColor={filterButtonColor} onClick={() => props.toggleDialog(props.filter, true)} />
                {filterEnabled && <IconOnlyButton icon={Close} bgColor="status-critical" onClick={props.resetFilter} />}
            </Box>
            {props.filter.showDialog && dialog}
        </Box>
    )
}

export default connector(NoteListFilter);