import React from 'react';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { Clock } from 'grommet';

import { AppState } from "../../store";

const mapState = ({ session }: AppState) => ({ 
    expiresOn: session.current?.expiresOn
});

const connector = connect(mapState);
type AllProps = ConnectedProps<typeof connector>

const SessionCountdown = (props: AllProps) => {
    if (!props.expiresOn) {
        return <React.Fragment />;
    }

    const expiredOnDate = moment(props.expiresOn);
    const expiredOn =  expiredOnDate.diff(moment());
    const duration = moment.duration(expiredOn, "milliseconds");
    const time = `PT${duration.hours()}H${duration.minutes()}M${duration.seconds()}S`;

    return <Clock type="digital" time={time} run="backward" alignSelf="center" />
}

export default connector(SessionCountdown);